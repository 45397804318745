<template>
  <v-card class="text-center">
    <v-card-title
      @click="$emit('heading-clicked')"
      class="white--text primary text-body-2 mt-n1 justify-center"
    >
      {{ heading }}
    </v-card-title>
    <v-container>
      <v-row justify="end">
        <v-btn
          @click="$emit('close-dialog')"
          class="mt-n9 mr-2"
          color="white"
          small
          icon
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <v-container class="mt-n9">
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    heading: String
  }
}
</script>
