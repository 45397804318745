<template>
  <v-card class="mb-n2">
    <v-row class="mt-3">
      <v-col>
        <v-card-title class="tour-phred-title">
          Create A Group
        </v-card-title>
        <v-form v-model="isFormValid" @submit.prevent="onRegister">
          <v-card-text>
            <v-row class="mb-n10">
              <v-col>
                <v-text-field
                  label="Name*"
                  v-model="name"
                  :rules="[validateNotEmpty]"
                  outlined
                  clearable
                  full-width
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-n10">
              <v-col>
                <v-text-field
                  label="Precedence (Integer)*"
                  v-model="precedence"
                  data-testid="precedence"
                  outlined
                  clearable
                  :rules="[validateIsNumber || '']"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Role Arn"
                  v-model="roleArn"
                  data-testid="roleArn"
                  outlined
                  clearable
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-n10">
              <v-col>
                <v-textarea
                  label="Description"
                  v-model="description"
                  data-testid="description"
                  outlined
                  clearable
                  auto-grow
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row justify="center">
            <v-btn
              color="primary"
              type="submit"
              large
              elevation="6"
              data-testid="register"
              :disabled="!isFormValid"
              class="my-4"
            >
              Submit
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import {
  validateNotEmpty,
  validateIsNumber
} from '@/utilities/validationRules'
import {
  CognitoIdentityProviderClient,
  CreateGroupCommand
} from "@aws-sdk/client-cognito-identity-provider";

const { fromCognitoIdentityPool } = require('@aws-sdk/credential-provider-cognito-identity')
const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity')
const REGION = "us-west-2";

export default {
  data() {
    return {
      // Initialize imported functions
      validateNotEmpty,
      validateIsNumber,
      // Registration data
      name: '',
      description: '',
      precedence: null,
      roleArn: '',
      isFormValid: false
    }
  },
  methods: {
    onRegister() {
      // Register a new client
      let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
      const client = new CognitoIdentityProviderClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
          }
        })
      });
      
      // Create group input
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        GroupName: this.name,
        Precedence: parseInt(this.precedence)
      }
      if (this.description != '') {
        input.Description = this.description
      }
      if (this.roleArn != '') {
        input.RoleArn = this.roleArn
      }

      // Create group
      const command = new CreateGroupCommand(input);
      client.send(command).then(data => {
        // console.log(data) // DEBUG
        const notification = {
          type: 'success',
          message: 'Group created successfully'
        }
        this.$store.dispatch('addNotification', notification)
      }).catch(err => {
        // console.error(err) // DEBUG
        const notification = {
          type: 'error',
          message: err.message
        }
        this.$store.dispatch('addNotification', notification)
      })
    }
  }
}
</script>

<style scoped>
.slide-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
</style>
