export function wordArrToEnum(arr) {
  /*
    turn an array of readable text into graphql enum value
*/
  let result = []
  arr.forEach((item) => {
    // split for multiple words
    let list = item.split(' ')
    // process for all the words
    let value = list[0].toUpperCase()
    for (let i = 1; i < list.length; i++) {
      value += '_' + list[i].toUpperCase()
    }
    result.push(value)
  })
  return result
}

export function enumArrToCamel(arr) {
  /* convert an array of enum to camel case */
  let result = []
  arr.forEach((words) => {
    // convert to lowercase
    words = words.toLowerCase()
    // split based on underscore
    words = words.split('_')
    let value = ''
    words.forEach((word) => {
      value += word[0].toUpperCase() + word.slice(1)
    })
    result.push(value)
  })
  return result
}

export function enumArrToWord(arr) {
  /* convert an array of enum to readable text (first-letter capitalized)*/
  let result = []
  arr.forEach((words) => {
    // convert to lowercase
    words = words.toLowerCase()
    // split based on underscore
    words = words.split('_')
    let value = words[0][0].toUpperCase() + words[0].slice(1)
    for (let i = 1; i < words.length; i++) {
      value += ' ' + words[i]
    }
    result.push(value)
  })
  return result
}

export function enumToWord(word) {
  /* convert an enum to readable word (first-letter capitalized)*/
  // convert to capitalized
  word = word[0] + word.toLowerCase().slice(1)
  // replace underscore with space
  word = word.replace('_', ' ')
  return word
}

export function enumToCamel(words) {
  /* convert an enum to camel case */
  // convert to lowercase
  words = words.toLowerCase()
  // split based on underscore
  words = words.split('_')
  let result = ''
  words.forEach((word) => {
    result += word[0].toUpperCase() + word.slice(1)
  })
  return result
}

export function wordToEnum(words) {
  /*
    turn a readable text into graphql enum value
*/
  // split for multiple words
  let list = words.split(' ')
  // process for all the words
  let value = list[0].toUpperCase()
  for (let i = 1; i < list.length; i++) {
    value += '_' + list[i].toUpperCase()
  }
  return value
}

export function camelToEnum(words) {
  /*
    turn a camel case word into an enum
*/
  words = words.split('')
  let result = words[0]
  for (let i = 1; i < words.length; i++) {
    if (words[i] === words[i].toUpperCase()) {
      result += '_'
    }
    result += words[i].toUpperCase()
  }
  return result
}

export default {
  wordArrToEnum,
  enumArrToCamel,
  enumArrToWord,
  enumToWord,
  enumToCamel,
  wordToEnum,
  camelToEnum
}
