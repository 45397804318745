import gql from "graphql-tag";

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchUsers = gql`
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchProjects = gql`
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: [SearchableProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProjectAggregationInput]
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhyloTreeSubProjects = gql`
  query SearchPhyloTreeSubProjects(
    $filter: SearchablePhyloTreeSubProjectFilterInput
    $sort: [SearchablePhyloTreeSubProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhyloTreeSubProjectAggregationInput]
  ) {
    searchPhyloTreeSubProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        userId
        projectId
        name
        alignLabels
        bacterialGroup
        branchLengths
        circular
        displayBackgroundColor
        displayClostridiumPathogens
        displayGenomeLength
        displayInnerNodes
        displayLeafLabels
        displayLeaves
        displayLegend
        displayOrder
        rings
        showMethanoGenus
        showOutgroup
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchComparisonSubProjects = gql`
  query SearchComparisonSubProjects(
    $filter: SearchableComparisonSubProjectFilterInput
    $sort: [SearchableComparisonSubProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableComparisonSubProjectAggregationInput]
  ) {
    searchComparisonSubProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        userId
        projectId
        name
        genes
        organisms
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchComparisonCharts = gql`
  query SearchComparisonCharts(
    $filter: SearchableComparisonChartFilterInput
    $sort: [SearchableComparisonChartSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableComparisonChartAggregationInput]
  ) {
    searchComparisonCharts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        userId
        projectId
        subProjectId
        chartType
        colour
        parameters
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhredSubProjects = gql`
  query SearchPhredSubProjects(
    $filter: SearchablePhredSubProjectFilterInput
    $sort: [SearchablePhredSubProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhredSubProjectAggregationInput]
  ) {
    searchPhredSubProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        userId
        projectId
        name
        search
        filterFamily
        filterReceptorLocation
        filterDifferentialStain
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhageFamilies = gql`
  query SearchPhageFamilies(
    $filter: SearchablePhageFamilyFilterInput
    $sort: [SearchablePhageFamilySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhageFamilyAggregationInput]
  ) {
    searchPhageFamilies(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        imageLocationInS3
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchGramStains = gql`
  query SearchGramStains(
    $filter: SearchableGramStainFilterInput
    $sort: [SearchableGramStainSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGramStainAggregationInput]
  ) {
    searchGramStains(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        imageLocationInS3
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchReceptorLocations = gql`
  query SearchReceptorLocations(
    $filter: SearchableReceptorLocationFilterInput
    $sort: [SearchableReceptorLocationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableReceptorLocationAggregationInput]
  ) {
    searchReceptorLocations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        value
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchBacterialHosts = gql`
  query SearchBacterialHosts(
    $filter: SearchableBacterialHostFilterInput
    $sort: [SearchableBacterialHostSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBacterialHostAggregationInput]
  ) {
    searchBacterialHosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhages = gql`
  query SearchPhages(
    $filter: SearchablePhageFilterInput
    $sort: [SearchablePhageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhageAggregationInput]
  ) {
    searchPhages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        phage
        owner
        alias
        comments
        family
        gramStain
        mainHost
        receptorLocation
        receptors
        receptorKeywords
        references
        verified
        doi
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhyloTrees = gql`
  query SearchPhyloTrees(
    $filter: SearchablePhyloTreeFilterInput
    $sort: [SearchablePhyloTreeSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhyloTreeAggregationInput]
  ) {
    searchPhyloTrees(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        name
        owner
        tree
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhyloTreeOrganisms = gql`
  query SearchPhyloTreeOrganisms(
    $filter: SearchablePhyloTreeOrganismFilterInput
    $sort: [SearchablePhyloTreeOrganismSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhyloTreeOrganismAggregationInput]
  ) {
    searchPhyloTreeOrganisms(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        index
        name
        owner
        phyloTree
        id
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhyloTreeMetaData = gql`
  query SearchPhyloTreeMetaData(
    $filter: SearchablePhyloTreeMetaDataFilterInput
    $sort: [SearchablePhyloTreeMetaDataSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhyloTreeMetaDataAggregationInput]
  ) {
    searchPhyloTreeMetaData(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        name
        index
        phylotree
        family
        genus
        genomeLength
        numberOfCodingSeq
        GC
        enzymes
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchEnzymes = gql`
  query SearchEnzymes(
    $filter: SearchableEnzymesFilterInput
    $sort: [SearchableEnzymesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEnzymesAggregationInput]
  ) {
    searchEnzymes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        pathway
        enzymes
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchKendraAbstracts = gql`
  query SearchKendraAbstracts(
    $filter: SearchableKendraAbstractsFilterInput
    $sort: [SearchableKendraAbstractsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableKendraAbstractsAggregationInput]
  ) {
    searchKendraAbstracts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        title
        owner
        abstract
        affiliations
        articleNumber
        authors
        authorsWithAffiliations
        doi
        documentType
        fundingDetails
        issue
        keywords
        publisher
        rawTitle
        references
        sourceTitle
        volume
        year
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhredSubmissions = gql`
  query SearchPhredSubmissions(
    $filter: SearchablePhredSubmissionFilterInput
    $sort: [SearchablePhredSubmissionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhredSubmissionAggregationInput]
  ) {
    searchPhredSubmissions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        phage
        owner
        alias
        cognitoUsername
        comments
        family
        gramStain
        mainHost
        receptorLocation
        receptors
        references
        verified
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchPhredIssues = gql`
  query SearchPhredIssues(
    $filter: SearchablePhredIssueFilterInput
    $sort: [SearchablePhredIssueSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePhredIssueAggregationInput]
  ) {
    searchPhredIssues(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        date
        email
        title
        issue
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchOrganisms = gql`
  query SearchOrganisms(
    $filter: SearchableOrganismFilterInput
    $sort: [SearchableOrganismSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrganismAggregationInput]
  ) {
    searchOrganisms(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        name
        owner
        family
        source
        country
        date
        etymology
        typeStrains
        rRNAGene16S
        rRNAGene16SDownloadLink
        rRNAGene16SNCBILink
        effectivePublication
        effectivePublicationDOI
        riskGroup
        sequenceLink
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchGenes = gql`
  query SearchGenes(
    $filter: SearchableGeneFilterInput
    $sort: [SearchableGeneSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGeneAggregationInput]
  ) {
    searchGenes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        organismName
        preferredName
        dnaSequence
        proteinSequence
        subGenomeLabel
        startIndex
        endIndex
        cogCategory
        descriptionFunction
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchGenomeReferences = gql`
  query SearchGenomeReferences(
    $filter: SearchableGenomeReferenceFilterInput
    $sort: [SearchableGenomeReferenceSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableGenomeReferenceAggregationInput]
  ) {
    searchGenomeReferences(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        faa
        fasta
        fna
        gff
        url
        strain
        etymology
        onesixsRNAGene
        fastaDownload
        NCBILink
        effectivePublication
        doi
        pubmed
        riskGroup
        completeness
        contamination
        typeStrains
        isLPSN
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchEggNOGGeneFunctions = gql`
  query SearchEggNOGGeneFunctions(
    $filter: SearchableeggNOGGeneFunctionFilterInput
    $sort: [SearchableeggNOGGeneFunctionSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableeggNOGGeneFunctionAggregationInput]
  ) {
    searchEggNOGGeneFunctions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        genome_reference_id
        query
        cogCategory
        description
        preferredName
        GOs
        EC
        keggKO
        keggPathway
        keggModule
        keggReaction
        keggRClass
        BRITE
        CAZy
        biggReaction
        PFAMs
        versionNum
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchEnzymeDialogData = gql`
  query SearchEnzymeDialogData(
    $filter: SearchableEnzymeDialogDataFilterInput
    $sort: [SearchableEnzymeDialogDataSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableEnzymeDialogDataAggregationInput]
  ) {
    searchEnzymeDialogData(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        fullName
        otherNames
        class
        shortName
        ECNumber
        KO
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUser = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      ownerName
      projects {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = gql`
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = gql`
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      owner
      userId
      projectName
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      phyloTreeProjects {
        nextToken
      }
      comparisonProjects {
        nextToken
      }
      phredProjects {
        nextToken
      }
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = gql`
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhyloTreeSubProject = gql`
  query GetPhyloTreeSubProject($id: ID!) {
    getPhyloTreeSubProject(id: $id) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      alignLabels
      bacterialGroup
      branchLengths
      circular
      displayBackgroundColor
      displayClostridiumPathogens
      displayGenomeLength
      displayInnerNodes
      displayLeafLabels
      displayLeaves
      displayLegend
      displayOrder
      rings
      showMethanoGenus
      showOutgroup
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const listPhyloTreeSubProjects = gql`
  query ListPhyloTreeSubProjects(
    $filter: ModelPhyloTreeSubProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhyloTreeSubProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        projectId
        name
        alignLabels
        bacterialGroup
        branchLengths
        circular
        displayBackgroundColor
        displayClostridiumPathogens
        displayGenomeLength
        displayInnerNodes
        displayLeafLabels
        displayLeaves
        displayLegend
        displayOrder
        rings
        showMethanoGenus
        showOutgroup
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComparisonSubProject = gql`
  query GetComparisonSubProject($id: ID!) {
    getComparisonSubProject(id: $id) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      genes
      organisms
      charts {
        nextToken
      }
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const listComparisonSubProjects = gql`
  query ListComparisonSubProjects(
    $filter: ModelComparisonSubProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComparisonSubProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        projectId
        name
        genes
        organisms
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComparisonChart = gql`
  query GetComparisonChart($id: ID!) {
    getComparisonChart(id: $id) {
      id
      owner
      userId
      projectId
      subProjectId
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      subProject {
        id
        owner
        userId
        projectId
        name
        genes
        organisms
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      chartType
      colour
      parameters
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const listComparisonCharts = gql`
  query ListComparisonCharts(
    $filter: ModelComparisonChartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComparisonCharts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        projectId
        subProjectId
        chartType
        colour
        parameters
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhredSubProject = gql`
  query GetPhredSubProject($id: ID!) {
    getPhredSubProject(id: $id) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      search
      filterFamily
      filterReceptorLocation
      filterDifferentialStain
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const listPhredSubProjects = gql`
  query ListPhredSubProjects(
    $filter: ModelPhredSubProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhredSubProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        userId
        projectId
        name
        search
        filterFamily
        filterReceptorLocation
        filterDifferentialStain
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhageFamily = gql`
  query GetPhageFamily($name: String!) {
    getPhageFamily(name: $name) {
      id
      name
      imageLocationInS3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPhageFamilies = gql`
  query ListPhageFamilies(
    $name: String
    $filter: ModelPhageFamilyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPhageFamilies(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        imageLocationInS3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGramStain = gql`
  query GetGramStain($name: String!) {
    getGramStain(name: $name) {
      id
      name
      imageLocationInS3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGramStains = gql`
  query ListGramStains(
    $name: String
    $filter: ModelGramStainFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGramStains(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        imageLocationInS3
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getReceptorLocation = gql`
  query GetReceptorLocation($name: String!) {
    getReceptorLocation(name: $name) {
      id
      name
      value
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listReceptorLocations = gql`
  query ListReceptorLocations(
    $name: String
    $filter: ModelReceptorLocationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReceptorLocations(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        value
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBacterialHost = gql`
  query GetBacterialHost($name: String!) {
    getBacterialHost(name: $name) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBacterialHosts = gql`
  query ListBacterialHosts(
    $name: String
    $filter: ModelBacterialHostFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBacterialHosts(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPhage = gql`
  query GetPhage($phage: String!) {
    getPhage(phage: $phage) {
      phage
      owner
      alias
      comments
      family
      gramStain
      mainHost
      receptorLocation
      receptors
      receptorKeywords
      references
      verified
      doi
      createdAt
      updatedAt
    }
  }
`;
export const listPhages = gql`
  query ListPhages(
    $phage: String
    $filter: ModelPhageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPhages(
      phage: $phage
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        phage
        owner
        alias
        comments
        family
        gramStain
        mainHost
        receptorLocation
        receptors
        receptorKeywords
        references
        verified
        doi
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhyloTree = gql`
  query GetPhyloTree($name: String!) {
    getPhyloTree(name: $name) {
      name
      owner
      tree
      organisms {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPhyloTrees = gql`
  query ListPhyloTrees(
    $name: String
    $filter: ModelPhyloTreeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPhyloTrees(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        owner
        tree
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhyloTreeOrganism = gql`
  query GetPhyloTreeOrganism($id: ID!) {
    getPhyloTreeOrganism(id: $id) {
      index
      name
      owner
      phyloTree
      id
      createdAt
      updatedAt
    }
  }
`;
export const listPhyloTreeOrganisms = gql`
  query ListPhyloTreeOrganisms(
    $filter: ModelPhyloTreeOrganismFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhyloTreeOrganisms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        index
        name
        owner
        phyloTree
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhyloTreeMetaData = gql`
  query GetPhyloTreeMetaData($id: ID!) {
    getPhyloTreeMetaData(id: $id) {
      name
      index
      phylotree
      family
      genus
      genomeLength
      numberOfCodingSeq
      GC
      enzymes
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPhyloTreeMetaData = gql`
  query ListPhyloTreeMetaData(
    $filter: ModelPhyloTreeMetaDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhyloTreeMetaData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        index
        phylotree
        family
        genus
        genomeLength
        numberOfCodingSeq
        GC
        enzymes
        id
        owner
      }
      nextToken
    }
  }
`;
export const getEnzymes = gql`
  query GetEnzymes($id: ID!) {
    getEnzymes(id: $id) {
      pathway
      enzymes
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEnzymes = gql`
  query ListEnzymes(
    $filter: ModelEnzymesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnzymes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pathway
        enzymes
        id
        owner
      }
      nextToken
    }
  }
`;
export const getKendraAbstracts = gql`
  query GetKendraAbstracts($title: String!) {
    getKendraAbstracts(title: $title) {
      title
      owner
      abstract
      affiliations
      articleNumber
      authors
      authorsWithAffiliations
      doi
      documentType
      fundingDetails
      issue
      keywords
      publisher
      rawTitle
      references
      sourceTitle
      volume
      year
      createdAt
      updatedAt
    }
  }
`;
export const listKendraAbstracts = gql`
  query ListKendraAbstracts(
    $title: String
    $filter: ModelKendraAbstractsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKendraAbstracts(
      title: $title
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        title
        owner
        abstract
        affiliations
        articleNumber
        authors
        authorsWithAffiliations
        doi
        documentType
        fundingDetails
        issue
        keywords
        publisher
        rawTitle
        references
        sourceTitle
        volume
        year
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhredSubmission = gql`
  query GetPhredSubmission($phage: String!) {
    getPhredSubmission(phage: $phage) {
      phage
      owner
      alias
      cognitoUsername
      comments
      family
      gramStain
      mainHost
      receptorLocation
      receptors
      references
      verified
      createdAt
      updatedAt
    }
  }
`;
export const listPhredSubmissions = gql`
  query ListPhredSubmissions(
    $phage: String
    $filter: ModelPhredSubmissionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPhredSubmissions(
      phage: $phage
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        phage
        owner
        alias
        cognitoUsername
        comments
        family
        gramStain
        mainHost
        receptorLocation
        receptors
        references
        verified
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhredIssue = gql`
  query GetPhredIssue($id: ID!) {
    getPhredIssue(id: $id) {
      id
      owner
      date
      email
      title
      issue
      createdAt
      updatedAt
    }
  }
`;
export const listPhredIssues = gql`
  query ListPhredIssues(
    $filter: ModelPhredIssueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhredIssues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        date
        email
        title
        issue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganism = gql`
  query GetOrganism($name: String!) {
    getOrganism(name: $name) {
      name
      owner
      family
      source
      country
      date
      etymology
      typeStrains
      rRNAGene16S
      rRNAGene16SDownloadLink
      rRNAGene16SNCBILink
      effectivePublication
      effectivePublicationDOI
      riskGroup
      sequenceLink
      genes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrganisms = gql`
  query ListOrganisms(
    $name: String
    $filter: ModelOrganismFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganisms(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        owner
        family
        source
        country
        date
        etymology
        typeStrains
        rRNAGene16S
        rRNAGene16SDownloadLink
        rRNAGene16SNCBILink
        effectivePublication
        effectivePublicationDOI
        riskGroup
        sequenceLink
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGene = gql`
  query GetGene($id: ID!) {
    getGene(id: $id) {
      id
      owner
      organismName
      organism {
        name
        owner
        family
        source
        country
        date
        etymology
        typeStrains
        rRNAGene16S
        rRNAGene16SDownloadLink
        rRNAGene16SNCBILink
        effectivePublication
        effectivePublicationDOI
        riskGroup
        sequenceLink
        createdAt
        updatedAt
      }
      preferredName
      dnaSequence
      proteinSequence
      subGenomeLabel
      startIndex
      endIndex
      cogCategory
      descriptionFunction
      createdAt
      updatedAt
    }
  }
`;
export const listGenes = gql`
  query ListGenes(
    $filter: ModelGeneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        organismName
        preferredName
        dnaSequence
        proteinSequence
        subGenomeLabel
        startIndex
        endIndex
        cogCategory
        descriptionFunction
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGenomeReference = gql`
  query GetGenomeReference($id: ID!) {
    getGenomeReference(id: $id) {
      id
      name
      faa
      fasta
      fna
      gff
      url
      strain
      etymology
      onesixsRNAGene
      fastaDownload
      NCBILink
      effectivePublication
      doi
      pubmed
      riskGroup
      completeness
      contamination
      typeStrains
      isLPSN
      eggNOGGeneFunctions {
        nextToken
      }
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const listGenomeReferences = gql`
  query ListGenomeReferences(
    $id: ID
    $filter: ModelGenomeReferenceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGenomeReferences(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        faa
        fasta
        fna
        gff
        url
        strain
        etymology
        onesixsRNAGene
        fastaDownload
        NCBILink
        effectivePublication
        doi
        pubmed
        riskGroup
        completeness
        contamination
        typeStrains
        isLPSN
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      nextToken
    }
  }
`;
export const getEggNOGGeneFunction = gql`
  query GetEggNOGGeneFunction($id: ID!) {
    getEggNOGGeneFunction(id: $id) {
      id
      genome_reference_id
      query
      cogCategory
      description
      preferredName
      GOs
      EC
      keggKO
      keggPathway
      keggModule
      keggReaction
      keggRClass
      BRITE
      CAZy
      biggReaction
      PFAMs
      versionNum
      genomeReference {
        id
        name
        faa
        fasta
        fna
        gff
        url
        strain
        etymology
        onesixsRNAGene
        fastaDownload
        NCBILink
        effectivePublication
        doi
        pubmed
        riskGroup
        completeness
        contamination
        typeStrains
        isLPSN
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const listEggNOGGeneFunctions = gql`
  query ListEggNOGGeneFunctions(
    $id: ID
    $filter: ModelEggNOGGeneFunctionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEggNOGGeneFunctions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        genome_reference_id
        query
        cogCategory
        description
        preferredName
        GOs
        EC
        keggKO
        keggPathway
        keggModule
        keggReaction
        keggRClass
        BRITE
        CAZy
        biggReaction
        PFAMs
        versionNum
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      nextToken
    }
  }
`;
export const getEnzymeDialogData = gql`
  query GetEnzymeDialogData($id: ID!) {
    getEnzymeDialogData(id: $id) {
      id
      fullName
      otherNames
      class
      shortName
      ECNumber
      KO
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const listEnzymeDialogData = gql`
  query ListEnzymeDialogData(
    $id: ID
    $filter: ModelEnzymeDialogDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnzymeDialogData(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fullName
        otherNames
        class
        shortName
        ECNumber
        KO
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      nextToken
    }
  }
`;
export const getGenomeReferenceStrain = gql`
  query GetGenomeReferenceStrain(
    $strain: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGenomeReferenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGenomeReferenceStrain(
      strain: $strain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        faa
        fasta
        fna
        gff
        url
        strain
        etymology
        onesixsRNAGene
        fastaDownload
        NCBILink
        effectivePublication
        doi
        pubmed
        riskGroup
        completeness
        contamination
        typeStrains
        isLPSN
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      nextToken
    }
  }
`;
export const getEnzymeDialogDataShortName = gql`
  query GetEnzymeDialogDataShortName(
    $shortName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelEnzymeDialogDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getEnzymeDialogDataShortName(
      shortName: $shortName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullName
        otherNames
        class
        shortName
        ECNumber
        KO
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      nextToken
    }
  }
`;
