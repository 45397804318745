<template>
  <v-container>
    <ManagementMain />
  </v-container>
</template>
<script>
import ManagementMain from '@/components/management/ManagementMain'

export default {
  name: 'Management',
  components: {
    ManagementMain
  }
}
</script>
