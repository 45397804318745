<template>
  <v-card class="mb-8 tour-phred-submission">
    <v-card-title>Selected phage</v-card-title>
    <v-form
      v-model="isFormEditReceptorValid"
      @submit.prevent="submitNewPhage"
    >
      <v-card-text>
        <v-row class="mb-n10">
          <v-col>
            <v-text-field
              label="Phage name*"
              v-model="editedItem.phage"
              :rules="[validateNotEmpty]"
              :disabled="submission.verified == 'yes'"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="familyOptions"
              item-text="name"
              item-value="value"
              label="Family*"
              v-model="editedItem.family"
              :rules="[validateNotEmpty]"
              outlined
              clearable
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mb-n10">
          <v-col>
            <v-text-field
              label="Main host*"
              v-model="editedItem.mainHost"
              :rules="[validateNotEmpty]"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="gramStainOptions"
              item-text="name"
              item-value="value"
              label="Differential stain*"
              v-model="editedItem.gramStain"
              :rules="[validateNotEmpty]"
              outlined
              clearable
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mb-n10">
          <v-col>
            <v-text-field
              label="Receptor(s)*"
              v-model="editedItem.receptors"
              :rules="[validateNotEmpty]"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Location of receptor*"
              v-model="editedItem.receptorLocation"
              :rules="[validateNotEmpty]"
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-n10">
          <v-col>
            <v-text-field
              label="References*"
              v-model="editedItem.references"
              :rules="[validateNotEmpty]"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Comments"
              v-model="editedItem.comments"
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mb-n10">
          <v-col>
            <v-text-field
              label="Alias"
              v-model="editedItem.alias"
              outlined
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Keywords"
              v-model="editedItem.receptorKeywords"
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-row justify="center" >
        <v-btn
          large
          color="error"
          class="my-3 mx-3"
          v-on:click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          large
          color="primary"
          type="submit"
          class="my-3 mx-3"
          :disabled="!isFormEditReceptorValid"
        >
          Save
        </v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import {
  listPhredSubmissions,
  listPhages
} from '@/graphql/queries.js'
import {
  deletePhredSubmission,
  createPhage,
  updatePhage
} from '@/graphql/mutations.js'
import { validateNotEmpty } from '@/utilities/validationRules'
import {
  wordToEnum,
  enumToWord,
  enumToCamel
} from '@/utilities/dataPreprocess.js'

export default {
  computed: {
    phredHeaders() {
      let data = [
        {
          text: 'Phage',
          value: 'phage'
        },
        {
          text: 'Family',
          value: 'family'
        },
        {
          text: 'Main host',
          value: 'mainHost'
        },
        {
          text: 'Differential stain',
          value: 'gramStain'
        },
        {
          text: 'Location of receptor',
          value: 'receptorLocation'
        },
        {
          text: 'Receptor(s)',
          value: 'receptors'
        },
        {
          text: 'Verified',
          value: 'verified'
        },
        {
          text: 'References',
          value: 'references',
          align: ' d-none'
        },
        {
          text: 'Alias',
          value: 'alias',
          align: ' d-none'
        },
        { text: 'References/Comments', value: 'data-table-expand' }
      ]
      return data
    }
  },
  props: [
    'submission'
  ],
  data() {
    return {
      // data
      listPhredSubmissions: [],
      search_submission: '',
      editedIndex: -1,
      editedItem: {
        alias: this.submission.alias,
        phage: this.submission.phage,
        family: this.submission.family,
        mainHost: this.submission.mainHost,
        gramStain: this.submission.gramStain,
        receptorKeywords: this.submission.receptorKeywords,
        receptorLocation: this.submission.receptorLocation,
        receptors: this.submission.receptors,
        references: this.submission.references,
        comments: this.submission.comments,
        owner: this.submission.owner
      },
      defaultItem: {
        Phage: '',
        Family: '',
        Main_host: '',
        Gram_stain: '',
        Receptor_location: '',
        Receptors: '',
        References: '',
        Comments: ''
      },
      gramStainOptions: [
        { name: 'Gram positive' },
        { name: 'Gram negative' },
        { name: 'Acid fast' },
        { name: 'Endospore' },
        { name: 'Capsule' },
        { name: 'Flagella' },
        { name: 'Spirochete' }
      ],
      familyOptions: [
        { name: 'Ackermannviridae' },
        { name: 'Autographiviridae' },
        { name: 'Ampullaviridae' },
        { name: 'Bicaudaviridae' },
        { name: 'Clavaviridae' },
        { name: 'Corticoviridae' },
        { name: 'Cystoviridae' },
        { name: 'Drexlerviridae' },
        { name: 'Finnlakeviridae' },
        { name: 'Fuselloviridae' },
        { name: 'Globuloviridae' },
        { name: 'Guttaviridae' },
        { name: 'Herelliviridae' },
        { name: 'Inoviridae' },
        { name: 'Leviviridae' },
        { name: 'Lipothrixviridae' },
        { name: 'Microviridae' },
        { name: 'Myoviridae' },
        { name: 'Plasmaviridae' },
        { name: 'Pleolipoviridae' },
        { name: 'Podoviridae' },
        { name: 'Portogloboviridae' },
        { name: 'Rudiviridae' },
        { name: 'Siphoviridae' },
        { name: 'Sphaerolipoviridae' },
        { name: 'Spiraviridae' },
        { name: 'Tectiviridae' },
        { name: 'Tristromaviridae' },
        { name: 'Turriviridae' },
        { name: 'Unclassified' }
      ],
      // visual
      dialogDelete: false,
      isFormEditReceptorValid: false
    }
  },
  methods: {
    validateNotEmpty,
    wordToEnum,
    enumToWord,
    enumToCamel,
    getColor(value) {
      if (value === 'Gram positive') return 'purple--text'
      else if (value === 'Gram negative' || value === 'no') return 'red--text'
      else if (value === 'yes') return 'primary--text'
      else return 'gray'
    },
    deleteSubmission() {
      this.$apollo
        .mutate({
          mutation: deletePhredSubmission,
          variables: {
            input: {
              phage: this.submission.phage
            }
          }
        })
        .then((data) => {
          // console.log(data) // DEBUG
          const notification = {
            type: 'success',
            message: 'Phage is successfully deleted'
          }
          this.$store.dispatch('addNotification', notification)
          this.listPhredSubmissions.items.splice(this.editedIndex, 1)
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
          this.$gtag.event(`Delete`, {
            event_category: 'Phred: Submission',
            event_label: `A submitted phage entry is deleted`
          })
        })
        .catch((err) => {
          console.log(err) // DEBUG
          const notification = {
            type: 'error',
            message: 'Submission deletion is unsuccessful'
          }
          this.$store.dispatch('addNotification', notification)
        })
    },
    submitNewPhage() {
      const phage = this.editedItem.phage
      const alias = this.editedItem.alias
      const mainHost = this.editedItem.mainHost
      const receptorKeywords = this.editedItem.receptorKeywords
      const receptorLocation = this.editedItem.receptorLocation
      const receptors = this.editedItem.receptors
      const references = this.editedItem.references
      const comments = this.editedItem.comments
      const owner = this.editedItem.owner
      // These variables are named this way because their type could be either object or string
      var gramStainUnknown = this.editedItem.gramStain
      var familyUnknown = this.editedItem.family
      // Need to add extra logic here because the fields will be an object if unchanged, but string if they are changed
      // The reason we keep it like this is because we need it in object form in order to have a default value, but v-select returns the string
      // when we change the selection
      if (typeof gramStainUnknown == "object") {
        gramStainUnknown = gramStainUnknown.value
      }
      if (typeof familyUnknown == "object") {
        familyUnknown = familyUnknown.value
      }
      const gramStain = this.wordToEnum(gramStainUnknown)
      const family = this.wordToEnum(familyUnknown)

      // Decide which mutation this is
      if (this.submission.verified == "yes") {
        this.$apollo
          .mutate({
            mutation: updatePhage,
            variables: {
              input: {
                phage,
                references,
                verified: 'yes',
                owner,
                alias,
                family,
                mainHost,
                gramStain,
                receptorKeywords,
                receptorLocation,
                receptors,
                ...(comments && { comments })
              }
            }
          })
          .then((data) => {
            // console.log(data) // DEBUG
            const notification = {
              type: 'success',
              message: 'Phage is successfully updated, please refresh the page. If there was a mistake, go to DynamoDB on the AWS console and remove it manually'
            }
            this.$store.dispatch('addNotification', notification)
            this.dialogAdd = false
            this.$gtag.event(`Submit`, {
              event_category: 'Phred: Submission',
              event_label: `A phage entry is submitted`
            })
          })
          .catch((err) => {
            console.log(err) // DEBUG
            const notification = {
              type: 'error',
              message: 'Something is wrong with the submission'
            }
            this.$store.dispatch('addNotification', notification)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: createPhage,
            variables: {
              input: {
                phage,
                references,
                verified: 'yes',
                owner,
                alias,
                family,
                mainHost,
                gramStain,
                receptorKeywords,
                receptorLocation,
                receptors,
                ...(comments && { comments })
              }
            }
          })
          .then((data) => {
            // console.log(data) // DEBUG
            const notification = {
              type: 'success',
              message: 'Phage is successfully updated, please refresh the page. If there was a mistake, go to DynamoDB on the AWS console and remove it manually'
            }
            this.$store.dispatch('addNotification', notification)
            this.dialogAdd = false
            this.$gtag.event(`Submit`, {
              event_category: 'Phred: Submission',
              event_label: `A phage entry is submitted`
            })
            this.deleteSubmission()
          })
          .catch((err) => {
            console.log(err) // DEBUG
            const notification = {
              type: 'error',
              message: 'Something is wrong with the submission'
            }
            this.$store.dispatch('addNotification', notification)
          })
      }

      this.cancel()
    },
    cancel() {
      this.$emit('cancel')
    }
  },
  created() {
    this.gramStainOptions.forEach((item, i) => {
      this.gramStainOptions[i].value = this.wordToEnum(item.name)
      // Sets default gramStain in the v-select --> Makes the field an object
      if (item.name == this.submission.gramStain) {
        this.editedItem.gramStain = this.gramStainOptions[i]
      }
    })
    this.familyOptions.forEach((item, i) => {
      this.familyOptions[i].value = this.wordToEnum(item.name)
      // Sets the default family in the v-select --> makes the field an object
      if (item.name == this.submission.family) {
        this.editedItem.family = this.familyOptions[i]
      }
    })
    if (this.editedItem.alias == null) {
      this.editedItem.alias = ""
    }
  },
  apollo: {
    listPhredSubmissions: {
      query: listPhredSubmissions,
      variables: {
        limit: 1000
      },
      error(err) {
        console.log(err)
      }
    },
    listPhages: {
      query: listPhages,
      variables: {
        limit: 1000
      },
      error(err) {
        console.log(err)
      }
    }
  }
}
</script>
