var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isSelected)?_c('v-card',{staticClass:"my-4 tour-phred-submission"},[_c('v-card-title',[_vm._v("Phage Receptor Database (PhReD)")]),_c('v-col',[_c('v-text-field',{staticClass:"mt-n3 mb-2",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search_submission),callback:function ($$v) {_vm.search_submission=$$v},expression:"search_submission"}})],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.listPhages.items && _vm.listPhages.items.length > 0
    ),expression:"\n      listPhages.items && listPhages.items.length > 0\n    "}],key:_vm.renderKey,staticClass:"elevation-1",attrs:{"search":_vm.search_submission,"headers":_vm.phredHeaders,"items":_vm.phredContent,"item-key":"phage","show-expand":"","single-select":"","show-select":"","loading":_vm.listPhages.length === 0 ? true : false,"loading-text":"Loading phages...","footer-props":{ itemsPerPageOptions: [10, 50, 100, -1] }},on:{"pagination":function (e) {
        _vm.itemCount = e.itemsLength
      },"current-items":function (e) {
        _vm.currentItems = e
      }},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('p',{staticClass:"my-2"},[_vm._v("References: "+_vm._s(item.references))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(!!item.comments && item.comments !== ' '),expression:"!!item.comments && item.comments !== ' '"}]},[_vm._v(" Comments: "+_vm._s(item.comments)+" ")])])]}},{key:"item.gramStain",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"my-1",class:_vm.getColor(item.gramStain)},[_vm._v(" "+_vm._s(item.gramStain)+" ")])]}},{key:"item.verified",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"my-1",class:_vm.getColor(item.verified)},[_vm._v(" "+_vm._s(item.verified)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):(_vm.isSelected)?_c('ManagePhredSubmissionEditor',{attrs:{"submission":this.selectedPhage},on:{"cancel":_vm.cancel}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }