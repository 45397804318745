<template>
  <div>
    <v-card class="mb-n2">
      <v-row class="mt-3">
        <v-col>
          <v-card-title class="tour-phred-title">
            Scrape for articles
          </v-card-title>
          <v-form v-model="isFormValid">
            <v-card-text>
              <v-row class="mb-n10">
                <v-col>
                  <v-text-field
                    label="Topic*"
                    v-model="topic"
                    :rules="[validateNotEmpty]"
                    outlined
                    clearable
                    full-width
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mb-n10">
                <v-col>
                  <v-text-field
                    label="Quantity*"
                    v-model="quantity"
                    :rules="[validateIsNumber || '']"
                    outlined
                    clearable
                    full-width
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mb-n10">
                <v-col>
                  <v-text-field
                    label="Recency : Articles from the last ___ days"
                    v-model="relevance"
                    :rules="[validateIsNumber || '']"
                    outlined
                    clearable
                    full-width
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row justify="center">
              <v-btn
                color="primary"
                large
                elevation="6"
                @click="request"
                class="my-4"
              >
                Scrape {{ quantity }} {{ topic }} articles
              </v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row v-if="sent">
        <v-col align="center">
          <p style="color:green">
            Request sent!
          </p>
        </v-col>
      </v-row>
    </v-card>

    <v-spacer> </v-spacer>

    <v-card class="mb-n2">
      <v-row class="mt-3">
        <v-col>
          <v-card-title class="tour-phred-title">
            Running Jobs
          </v-card-title>
        </v-col>
        <v-btn
          @click="loadJobs('RUNNING')"
          title="Load Jobs"
          class="mt-3 mr-6"
          color="primary"
          icon
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <!-- <v-card v-for="job in jobs" :key="`${job.jobName}-${job.jobId}`">
            <v-row>
              <v-col>
                <v-card-actions class="justify-center">
                  <v-btn text x-large class="primary--text font-weight-bold">
                    {{ stringAdjust(job.jobName) }}
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="justify-center">
                  <v-btn class="error" @click="deleteJob(job.jobId)"
                    >Cancel job <v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card> -->
        </v-col>
        <v-row>
          <template>
            <div>
              <v-data-table
                v-if="jobs.length > 0"
                :headers="headers"
                :items="jobs"
                class="elevation-1"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small @click="deleteJob(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
              <h2 v-if="jobs.length === 0">No active jobs at the moment!</h2>
            </div>
          </template>
        </v-row>
      </v-row>
    </v-card>

    <v-card class="mb-n2">
      <v-row class="mt-3">
        <v-col>
          <v-card-title class="tour-phred-title">
            Completed Jobs
          </v-card-title>
        </v-col>
        <v-btn
          @click="loadJobs('SUCCEEDED')"
          title="Load Jobs"
          class="mt-3 mr-6"
          color="primary"
          icon
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <!-- <v-card v-for="job in jobs" :key="`${job.jobName}-${job.jobId}`">
            <v-row>
              <v-col>
                <v-card-actions class="justify-center">
                  <v-btn text x-large class="primary--text font-weight-bold">
                    {{ stringAdjust(job.jobName) }}
                  </v-btn>
                </v-card-actions>
                <v-card-actions class="justify-center">
                  <v-btn class="error" @click="deleteJob(job.jobId)"
                    >Cancel job <v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card> -->
        </v-col>
        <v-row>
          <template>
            <div>
              <v-data-table
                :headers="completedHeaders"
                :items="allJobs"
                class="elevation-1"
              >
              </v-data-table>
            </div>
          </template>
        </v-row>
      </v-row>
    </v-card>

    <v-card class="mb-n2">
      <v-row class="mt-3">
        <v-col>
          <v-card-title class="tour-phred-title">
            Logs
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div>
            <v-card min-height="150" class="my-4" width="1100">
              <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=2" width="350" height="150" frameborder="0"></iframe>
            &nbsp;
            <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=4" width="350" height="150" frameborder="0"></iframe>
            &nbsp;
            <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=6" width="350" height="150" frameborder="0"></iframe>
            </v-card>

            <v-card min-height="400" class="my-4" width="1100">
              <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=8" width="1050" height="400" frameborder="0"></iframe>
            </v-card>
            <v-card min-height="400" class="my-4" width="1100">
              <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=10" width="1050" height="400" frameborder="0"></iframe>
            </v-card>

            <v-card min-height="350" class="my-4" width="1100">
              <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=&from=now-30d&to=now&theme=light&panelId=12" width="350" height="350" frameborder="0"></iframe>
            &nbsp;
            <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=14" width="350" height="350" frameborder="0"></iframe>
            &nbsp;
            <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=16" width="350" height="350" frameborder="0"></iframe>
            </v-card>

            <v-card min-height="400" class="my-4" width="1100">
              <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=18" width="525" height="400" frameborder="0"></iframe>
            &nbsp;
            <iframe src="https://grafananginx.bio-conversion.org/d-solo/RZ_IZ927z/articlescrapingdashboard?orgId=1&from=now-30d&to=now&theme=light&panelId=20" width="525" height="400" frameborder="0"></iframe>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
const {
  fromCognitoIdentityPool,
} = require('@aws-sdk/credential-provider-cognito-identity')
const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity')

import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { validateNotEmpty, validateIsNumber } from '@/utilities/validationRules'
import * as AWS from '@aws-sdk/client-batch'
import axios from 'axios'

export default {
  data() {
    return {
      // Initialize imported functions
      validateNotEmpty,
      validateIsNumber,
      // Registration data
      topic: '',
      quantity: '',
      relevance: '',
      isFormValid: false,
      token: this.$store.state.user.session.idToken.jwtToken,
      reqBody: {},
      sent: false,
      lastSent: '',
      jobs: [],
      headers: [
        { text: 'Job ID', value: 'id' },
        { text: 'Submited by', value: 'user' },
        { text: 'Topic', value: 'topic' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Recency (days)', value: 'recency' },
        { text: 'Submited at', value: 'time' },
        { text: 'Actions', value: 'actions' },
      ],
      completedHeaders: [
        { text: 'Job ID', value: 'id' },
        { text: 'Submited by', value: 'user' },
        { text: 'Topic', value: 'topic' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Recency (days)', value: 'recency' },
        { text: 'Submited at', value: 'time' },
      ],
      allJobs: [],
      tab: null,
      tabs: [
        {name: 'Article Pipeline'}
        ]
    }
  },
  created() {
    console.log('HASAAAAAAAAAA')
    this.token = this.$store.state.user.session.idToken.jwtToken

    let email = this.$store.state.user.userAttributes.email
    console.log('token', this.token)
    console.log('email', email)
    this.loadJobs('SUCCEEDED')
    this.loadJobs('RUNNING')
  },
  methods: {
    parseJobObject(job) {
      let name = job.jobName
      let id = job.jobId
      let topic = name.split('-')[1]
      let quantity = parseInt(name.split('-')[3])
      let recency = parseInt(name.split('-')[5])
      let user = name.split('-')[7]
      let time = new Date(job.createdAt)
        .toString()
        .split(' GMT')[0]
        .substring(4)
      return {
        id: id,
        topic: topic,
        quantity: quantity,
        recency: recency,
        user: user,
        time: time,
      }
    },
    deleteJob(id) {
      const REGION = 'us-west-2'
      let cognitoProviderName =
        'cognito-idp.' +
        REGION +
        '.amazonaws.com/' +
        process.env.VUE_APP_COGNITO_POOL_ID
      const client = new AWS.Batch({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId:
            process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_USERSTATS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
              .jwtToken,
          },
        }),
      })
      var params = {
        jobId: id,
        reason: 'Terminating job.',
      }
      client.terminateJob(params, function(err, data) {
        if (err) console.log(err, err.stack)
        // an error occurred
        else console.log(data) // successful response
      })
    },
    stringAdjust(s) {
      return s.replace('---', '@').replace('----', '.')
    },
    loadJobs(state) {
      const REGION = 'us-west-2'
      let cognitoProviderName =
        'cognito-idp.' +
        REGION +
        '.amazonaws.com/' +
        process.env.VUE_APP_COGNITO_POOL_ID
      const client = new AWS.Batch({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId:
            process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_USERSTATS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
              .jwtToken,
          },
        }),
      })
      var params = {
        jobQueue: 'Article-queue',
        jobStatus: state,
      }

      client.listJobs(params, (err, data) => {
        if (err) {
          console.log(err, err.stack)
        } else {
          console.log(data)
          if (state === 'RUNNING') {
            this.jobs = data.jobSummaryList.map(this.parseJobObject)
          } else if (state === 'SUCCEEDED') {
            this.allJobs = data.jobSummaryList.map(this.parseJobObject)
          }

          console.log(this.jobs)
        }
        // successful response
      })
    },
    request() {
      //this.loadJobs()
      this.token = this.$store.state.user.session.idToken.jwtToken

      let email = this.$store.state.user.userAttributes.email

      let endpoint =
        process.env.VUE_APP_ENV === 'production'
          ? 'https://927dknsd17.execute-api.us-west-2.amazonaws.com/default'
          : 'https://jtvbi5p5b0.execute-api.us-west-2.amazonaws.com/default'

      this.reqBody = {
        topic: this.topic,
        quantity: this.quantity,
        relevance: this.relevance,
        user: email,
      }

      //endpoint = 'https://bdjnzsr2c6.execute-api.us-west-2.amazonaws.com/default/Article-Batch-Job-Lambda'

      var config = {
        method: 'POST',
        //withCredentials: true,
        url: endpoint,
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
        token: this.token,

        data: this.reqBody,
      }

      axios(config)
        .then(function(response) {
          console.log(response)
        })
        .catch(function(error) {
          console.log(error)
          return
        })
      this.lastSent = this.reqBody
      this.sent = true
    },
  },
}
</script>

<style scoped>
.slide-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}

.v-card {
  margin: 50px 10px 50px 10px;
}

.v-data-table {
  margin: 50px 70px 50px 70px;
}

h2 {
  margin: 10px 70px 80px 70px;
}
</style>
