<template>
  <v-card class="my-4 tour-phred-issue">
    <v-card-title>Submitted phage issues</v-card-title>
    <v-col>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        class="mt-n3 mb-2"
      ></v-text-field>
    </v-col>
    <v-data-table
      :search="search"
      v-show="
        listPhredIssues.items && listPhredIssues.items.length > 0
      "
      :headers="headers"
      :items="listPhredIssues.items"
      item-key="id"
      class="elevation-1"
      show-expand
      show-select
      :loading="listPhredIssues.length === 0 ? true : false"
      loading-text="Loading issues..."
      :footer-props="{ itemsPerPageOptions: [5, 25, 50, -1] }"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p class="my-2">Issue: {{ item.issue }}</p>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { listPhredIssues } from '@/graphql/queries.js'
import { deletePhredIssue } from '@/graphql/mutations.js'

export default {
  computed: {
    headers() {
      let data = [
        {
          text: 'Issue ID',
          value: 'id'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Created',
          value: 'date'
        },
        {
          text: 'Issue',
          value: 'issue',
          align: ' d-none'
        },
        {
          text: 'Issue',
          value: 'data-table-expand'
        }
      ]
      return data
    },
  },
  data() {
    return {
      // data
      listPhredIssues: [],
      search: '',
      editedIndex: -1,
      editedItem: {
        ID: '',
        Email: '',
        Title: '',
        Created: '',
        Issue: ''
      },
      defaultItem: {
        ID: '',
        Email: '',
        Title: '',
        Created: '',
        Issue: ''
      },
      // visual
      dialogDelete: false
    }
  },
  methods: {
    // deleteItem(item) {
    //   this.editedIndex = this.listPhredIssues.items.indexOf(item)
    //   this.editedItem = Object.assign({}, item)
    //   this.dialogDelete = true
    // },
    // deleteItemConfirm() {
    //   this.$apollo
    //     .mutate({
    //       mutation: deletePhredIssue,
    //       variables: {
    //         input: {
    //           id: this.editedItem.ID
    //         }
    //       }
    //     })
    //     .then((data) => {
    //       console.log(data) // DEBUG
    //       const notification = {
    //         type: 'success',
    //         message: 'Issue is successfully deleted'
    //       }
    //       this.$store.dispatch('addNotification', notification)
    //       this.listPhredIssues.items.splice(this.editedIndex, 1)
    //       this.dialogDelete = false
    //       this.$nextTick(() => {
    //         this.editedItem = Object.assign({}, this.defaultItem)
    //         this.editedIndex = -1
    //       })
    //       this.$gtag.event(`Delete`, {
    //         event_category: 'Phred: Issue',
    //         event_label: `A submitted phage issue is deleted`
    //       })
    //     })
    //     .catch((err) => {
    //       console.log(err) // DEBUG
    //       const notification = {
    //         type: 'error',
    //         message: 'Deletion is unsuccessful'
    //       }
    //       this.$store.dispatch('addNotification', notification)
    //     })
    // },
  },
  apollo: {
    listPhredIssues: {
      query: listPhredIssues,
      error(err) {
        console.log(err)
      }
    }
  }
}
</script>
