<template>
  <v-card>
    <v-col>
      <v-row class="mt-3">
        <v-card-title class="ml-5"> Terms and Conditions </v-card-title>
      </v-row>
      <v-row class="mx-5">
        <v-text-field
          :disabled="!editing || !versionFromAWS.length"
          v-model="versionToEdit"
          label="Version"
          style="max-width: 200px"
          :loading="!versionFromAWS.length"
        ></v-text-field>
      </v-row>
      <v-row class="ma-5">
        <v-textarea
          :loading="!termsAndConditionsFromAWS.length"
          name="text-area-for-tnc"
          hint="Change T&C"
          :disabled="!editing || !termsAndConditionsFromAWS.length"
          auto-grow
          v-model="termsAndConditionsToEdit"
          counter
        ></v-textarea>
      </v-row>
      <v-row>
        <v-container v-if="!editing">
          <v-btn color="primary" class="ma-3" @click="() => (editing = true)">
            Edit
          </v-btn>
        </v-container>
        <v-container v-else>
          <v-btn
            color="error"
            class="ma-3"
            @click="
              () => {
                editing = false;
                // reset the terms and conditions if we are canceling editing
                termsAndConditionsToEdit = termsAndConditionsFromAWS;
                versionToEdit = versionFromAWS;
              }
            "
          >
            Cancel
          </v-btn>
          <v-btn color="primary" class="ma-3" @click="onSave"> Save </v-btn>
        </v-container>
      </v-row>
    </v-col>
  </v-card>
</template>

<script>
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand,
  GetObjectCommand,
} from '@aws-sdk/client-s3';
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda';

const REGION = 'us-west-2';

export default {
  data() {
    return {
      s3Client: null,
      editing: false,
      // the T&C and version stored in AWS
      termsAndConditionsFromAWS: '',
      versionFromAWS: '',
      // the T&C and version used to display and edit
      termsAndConditionsToEdit: '',
      versionToEdit: '',
      // the object key where the terms and conditions are stored
      latestTnCObjectKey: 'latest/termsAndConditions',
    };
  },
  created() {
    let cognitoProviderName =
      'cognito-idp.' +
      REGION +
      '.amazonaws.com/' +
      process.env.VUE_APP_COGNITO_POOL_ID;
    // get the s3Client on initial create
    this.s3Client = new S3Client({
      region: REGION,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_IMAGE,
        logins: {
          [`${cognitoProviderName}`]:
            this.$store.state.user.session.idToken.jwtToken,
        },
      }),
    });
    // get the T&C on the initial load
    this.getTermsNConditions();
  },
  methods: {
    // reset the T&C flag for all users
    async resetTnCFlagForAllUsers() {
      let cognitoProviderName =
        'cognito-idp.' +
        REGION +
        '.amazonaws.com/' +
        process.env.VUE_APP_COGNITO_POOL_ID;
      const lambda = new LambdaClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_LAMBDA,
          logins: {
            [`${cognitoProviderName}`]:
              this.$store.state.user.session.idToken.jwtToken,
          },
        }),
      });
      try {
        lambda.send(
          new InvokeCommand({
            FunctionName: 'ResetTermsAndConditionsForAllUsers',
          })
        );

        let notification = {
          type: 'success',
          message: 'Successfully reset all user T&C acceptance',
        };
        this.$store.dispatch('addNotification', notification);
      } catch (err) {
        console.log(err);
        let notification = {
          type: 'error',
          message: 'Error reseting T&C for users',
        };
        this.$store.dispatch('addNotification', notification);
      }
    },
    // get the T&C from AWS
    async getTermsNConditions() {
      try {
        // Get the object from the Amazon S3 bucket. It is returned as a ReadableStream.
        const data = await this.s3Client.send(
          new GetObjectCommand({
            Bucket: 'termsnconditionsagreement',
            Key: this.latestTnCObjectKey,
            // do not cache the result so that we get the correct result everytime we get the object
            ResponseCacheControl: 'no-cache',
          })
        );

        // decode the readable stream response from S3
        let reader = data.Body.getReader();
        let decoder = new TextDecoder('utf-8');

        const responseFromBucket = await reader.read().then(function (result) {
          return decoder.decode(result.value);
        });

        const jsonResponse = JSON.parse(responseFromBucket);

        // set the terms and conditions retrieved from AWS
        this.termsAndConditionsFromAWS = jsonResponse.data;
        this.termsAndConditionsToEdit = jsonResponse.data;
        // the version retrieved from AWS
        this.versionFromAWS = jsonResponse.version;
        this.versionToEdit = jsonResponse.version;
      } catch (e) {
        console.error(e);
        const notification = {
          type: 'error',
          message: 'There was an error fetching the T&C',
        };
        this.$store.dispatch('addNotification', notification);
        return;
      }
    },
    async onSave() {
      this.editing = false;
      // if nothing has changed
      if (
        this.termsAndConditionsFromAWS === this.termsAndConditionsToEdit &&
        this.versionFromAWS === this.versionToEdit
      ) {
        const notification = {
          type: 'error',
          message: 'No change in T&C observed',
        };
        this.$store.dispatch('addNotification', notification);
        return;
      }

      // make the jsonObject from the previous T&C to store in old folder in S3
      let oldTnCObjectToStore = {
        data: this.termsAndConditionsFromAWS,
        version: this.versionFromAWS,
      };

      // make the jsonObject from the new T&C to store in new folder in S3
      let newTnCObjectToStore = {
        data: this.termsAndConditionsToEdit,
        version: this.versionToEdit,
      };

      // format of this object key: 'old/v0.1-1656126735219', in the old directory
      const uploadParamsForOldTnC = {
        Bucket: 'termsnconditionsagreement',
        Key: `old/v${this.versionFromAWS}-${Date.now()}`,
        Body: JSON.stringify(oldTnCObjectToStore),
        ContentType: 'text/plain',
      };

      // format of this object key: 'latest/termsAndConditions', in the latest directory
      const uploadParamsForNewTnC = {
        Bucket: 'termsnconditionsagreement',
        Key: this.latestTnCObjectKey,
        Body: JSON.stringify(newTnCObjectToStore),
        ContentType: 'text/plain',
      };

      // delete params for the latest T&C from the latest/ folder in S3
      const deleteParamsForOldTnC = {
        Bucket: 'termsnconditionsagreement',
        Key: this.latestTnCObjectKey,
      };

      try {
        // save the object in old folder in the bucket
        await this.s3Client.send(new PutObjectCommand(uploadParamsForOldTnC));

        let notification = {
          type: 'success',
          message: 'Successfully stored the previous T&C',
        };
        this.$store.dispatch('addNotification', notification);

        try {
          // delete the current object from latest/ as it is stored in the old/ now
          await this.s3Client.send(
            new DeleteObjectCommand(deleteParamsForOldTnC)
          );

          try {
            // upload a new object with the same key
            await this.s3Client.send(
              new PutObjectCommand(uploadParamsForNewTnC)
            );
            // change the terms and conditions local state as AWS has been modified
            this.termsAndConditionsFromAWS = this.termsAndConditionsToEdit;
            const notification = {
              type: 'success',
              message: 'Latest T&C was updated successfully',
            };
            this.$store.dispatch('addNotification', notification);

            // if we have changed the version of the terms and conditions then reset T&C for all users
            if (this.versionFromAWS !== this.versionToEdit) {
              this.versionFromAWS = this.versionToEdit;
              this.resetTnCFlagForAllUsers();
            }
          } catch (e) {
            console.error(e);
            const notification = {
              type: 'error',
              message: 'There was an error changing the T&C',
            };
            this.$store.dispatch('addNotification', notification);
          }
        } catch (err) {
          console.error(err);
          const notification = {
            type: 'error',
            message:
              'There was an error deleting the previous T&C, could not update the previous T&C',
          };
          this.$store.dispatch('addNotification', notification);
        }
      } catch (e) {
        console.error(e);
        let notification = {
          type: 'error',
          message: 'Error saving previous T&C, aborting change of T&C',
        };
        this.$store.dispatch('addNotification', notification);

        return;
      }
    },
  },
};
</script>
