<template>
  <div>
    <v-row justify="center" class="my-2">
      <v-img max-width="300" src="@/assets/biodf/BioDF-textlogo.png"></v-img>
    </v-row>
    <v-row justify="center">
      <p class="primary--text">What do you want to do?</p>
    </v-row>
    <v-row class="mt-2" justify="center">
      <v-spacer></v-spacer>
      <v-col cols="12" md="6">
        <v-text-field
          label="Search users and groups"
          append-icon="mdi-magnify"
          background-color="white"
          @click:append="search_lists"
          @keyup.enter="search_lists"
          v-model="search"
          outlined
          single-line
          rounded
        ></v-text-field
      ></v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-btn
        class="primary--text"
        icon
        v-if="!states.menu"
        @click="enable_menu"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </v-row>
    <v-row>
      <v-col
        class="mt-2"
        cols="12"
        sm="6"
        lg="3"
        v-for="button in buttons"
        v-if="states.menu"
        :key="button.name"
      >
        <v-card>
          <v-card-actions class="justify-center">
            <v-btn
              text
              x-large
              class="primary--text font-weight-bold"
              @click="management_selection(button)"
            >
              {{ button.name }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-col>
      <ListUsersTable v-if="states.searching" :search="search" />
      <ListGroupsTable v-if="states.searching" :search="search" />
      <ManagementCreateUser v-if="states.create_user" />
      <ManagementListUsers
        v-if="states.list_users"
        v-on:switch-to-create-user="switchToCreateUser"
      />
      <ManagementCreateGroup v-if="states.create_group" />
      <ManagementListGroups
        v-if="states.list_groups"
        v-on:switch-to-create-group="switchToCreateGroup"
      />
      <ManagementManagePhredSubmissions v-if="states.manage_phred" />
      <ManagementManagePhredIssues v-if="states.manage_phred" />
      <ManagementManagePhages v-if="states.manage_phred" />
      <ManagementArticles v-if="states.article_scraping" />
      <ListOfTermsAndConditions v-if="states.terms_n_conditions" />
      <FeatureFlags v-if="states.editFeatureFlags" />
    </v-col>
  </div>
</template>

<script>
import ListUsersTable from './ListUsersTable';
import ListGroupsTable from './ListGroupsTable';
import ManagementCreateUser from './ManagementCreateUser';
import ManagementListUsers from './ManagementListUsers';
import ManagementCreateGroup from './ManagementCreateGroup';
import ManagementListGroups from './ManagementListGroups';
import ManagementManagePhredSubmissions from './ManagementManagePhredSubmissions';
import ManagementManagePhredIssues from './ManagementManagePhredIssues';
import ManagementManagePhages from './ManagementManagePhages';
import ManagementArticles from './ManagementArticles';
import ListOfTermsAndConditions from './ListOfTermsAndConditions';
import FeatureFlags from './FeatureFlags';

export default {
  name: 'ManagementMain',
  data() {
    return {
      buttons: [
        {
          name: 'Create User',
          func: 'create_user',
        },
        {
          name: 'List Users',
          func: 'list_users',
        },
        {
          name: 'Create Group',
          func: 'create_group',
        },
        {
          name: 'List Groups',
          func: 'list_groups',
        },
        // Manage Connections and Collaborators
        {
          name: 'Manage C&C',
          func: 'manage_cc',
        },
        {
          name: 'Manage Phred',
          func: 'manage_phred',
        },
        {
          name: 'Article Scraping',
          func: 'article_scraping',
        },
        { name: 'Edit T&C', func: 'terms_n_conditions' },
        { name: 'Edit Feature Flags', func: 'editFeatureFlags' },
      ],
      // Component states
      states: {
        menu: true,
        create_user: false,
        list_users: false,
        create_group: false,
        list_groups: false,
        manage_cc: false,
        searching: false,
        manage_phred: false,
        article_scraping: false,
        terms_n_conditions: false,
        editFeatureFlags: false,
      },
      // Registration info
      email: '',
      search: '',
    };
  },
  components: {
    ListUsersTable,
    ListGroupsTable,
    ManagementCreateUser,
    ManagementListUsers,
    ManagementCreateGroup,
    ManagementListGroups,
    ManagementManagePhredSubmissions,
    ManagementManagePhredIssues,
    ManagementManagePhages,
    ManagementArticles,
    ListOfTermsAndConditions,
    FeatureFlags,
  },
  methods: {
    management_selection(button) {
      this.states.menu = false;
      this.states.searching = false;
      this.states[button.func] = true;
    },
    enable_menu() {
      // Effectively resets the state of everything
      for (var key in this.states) {
        this.states[key] = false;
      }
      this.states.menu = true;
    },
    switchToCreateGroup() {
      this.states.list_groups = false;
      this.states.create_group = true;
    },
    switchToCreateUser() {
      // exit all other possible states
      this.states.list_users = false;
      this.email = '';

      // enter create user state
      this.states.create_user = true;
    },
    search_lists() {
      this.enable_menu();
      this.states.searching = true;
    },
  },
};
</script>
