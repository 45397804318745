<template>
  <v-card class="mb-n2">
    <v-row class="mt-3">
      <v-card-title class="tour-phred-title ml-5">
        List of Groups
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        @click="createGroup"
        title="Create a group"
        class="mt-3 mr-6"
        color="primary"
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-col>
      <v-autocomplete
        :items="groups"
        item-text="name"
        label="Group"
        outlined
        clearable
        class="mt-2"
        data-testid="group"
        v-model="group"
      ></v-autocomplete>
      <v-autocomplete
        :items="included_user_list"
        item-text="email"
        label="Remove Current Users"
        outlined
        clearable
        multiple
        deletable-chips
        chips
        class="mt-2"
        data-testid="users"
        v-model="selected_users_remove"
        v-if="group_selected"
      ></v-autocomplete>
      <v-autocomplete
        :items="excluded_user_list"
        item-text="email"
        label="Add New Users"
        outlined
        clearable
        multiple
        deletable-chips
        chips
        class="mt-2"
        data-testid="users"
        v-model="selected_users_add"
        v-if="group_selected"
      ></v-autocomplete>
    </v-col>
    <v-card-actions
      class="justify-center"
      v-if="group_selected"
    >
      <v-btn
        color="error"
        large
        class="mb-3 mt-n6 mx-3"
        @click="toggle_delete"
      >
        Delete Group
      </v-btn>
      <v-btn
        :disabled="enableDelete"
        color="primary"
        large
        class="mb-3 mt-n6 mx-3"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
    <v-card-text
      class="text-center"
      v-if="enableDelete"
    >
      Are you sure you want to delete this group? This action is irreversible.
      Please write <b>Confirm Delete</b> to permanently delete the group (but not the users contained within).
    </v-card-text>
    <v-card-actions
      class="justify-center"
      v-if="enableDelete"
    >
      <v-form v-model="isFormValid">
        <v-row>
          <v-col>
            <v-text-field
              placeholder="Confirm Delete"
              :rules="[validateDelete]"
              solo
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          class="mt-n5 mb-3"
        >
          <v-btn
            :disabled="!isFormValid"
            color="error"
            @click="delete_group"
            large
          >
            Delete
          </v-btn>
        </v-row>
      </v-form>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CognitoIdentityProviderClient,
  ListGroupsCommand,
  AdminAddUserToGroupCommand,
  AdminRemoveUserFromGroupCommand,
  ListUsersInGroupCommand,
  ListUsersCommand,
  DeleteGroupCommand
} from "@aws-sdk/client-cognito-identity-provider";
import { validateDelete } from '@/utilities/validationRules';

const { fromCognitoIdentityPool } = require('@aws-sdk/credential-provider-cognito-identity')
const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity')
const REGION = "us-west-2";

export default {
  data() {
    return {
      // Initialize imported properties
      validateDelete,
      // Initialize group info
      group: null,
      groups: [],
      // These contain an array of emails (for readability on the interface)
      selected_users_remove: [],
      selected_users_add: [],
      // included_user_list contains all users in the group, excluded_user_list is everyone else
      // Since these are arrays of objects, we use a set of cognito ids to make searching for membership faster
      included_user_list: [],
      excluded_user_list: [],
      // Dictionary used to associate an email with a username quickly
      email_map: {},
      user_set: null,
      user: '',
      group_selected: false,
      isFormValid: false,
      enableDelete: false
    }
  },
  created() {
    this.get_groups()
  },
  watch: {
    group(selection) {
      this.enableDelete = false
      if (selection != null) {
        this.group_selected = true
        this.fetch_users()
      } else {
        this.group_selected = false
      }
    }
  },
  methods: {
    get_groups(client=null, nextToken=undefined) {
      if (client === null) {
        // Register a new client
        let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
        var client = new CognitoIdentityProviderClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_USERSTATS,
            logins: {
              [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
            }
          })
        });
      }
      
      // Get list of groups
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        NextToken: nextToken
      }
      var command = new ListGroupsCommand(input);
      client.send(command).then(data => {
        for (let i = 0; i < data.Groups.length; i++) {
          this.groups.push(data.Groups[i].GroupName)
        }

        // Paginate if necessary
        if (data.NextToken != undefined) {
          this.get_groups(client, data.NextToken)
        }
      })
    },
    createGroup() {
      this.$emit('switch-to-create-group')
    },
    fetch_users() {
      // Function that resets a bunch of data structures, and then gets the users.
      // Needed a separate function so that we could recurse without resetting every time

      // Data structures used in the function
      this.included_user_list = []
      this.user_set = new Set()
      this.email_map = {}
      // Data structures that need to be reset, but aren't in the function
      this.selected_users_remove = []
      this.selected_users_add = []
      this.get_included_users()
    },
    get_included_users(client=null, nextToken=undefined) {
      if (client === null) {
        // Register a new client
        let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
        var client = new CognitoIdentityProviderClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
            logins: {
              [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
            }
          })
        });
      }

      // Get list of users
      const input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        GroupName: this.group,
        NextToken: nextToken
      }
      const command = new ListUsersInGroupCommand(input);
      client.send(command).then(data => {
        for (let i = 0; i < data.Users.length; i++) {
          let single_user = {};
          for (let j = 0; j < data.Users[i].Attributes.length; j++) {
            if (data.Users[i].Attributes[j].Name == "sub") {
              single_user.username = data.Users[i].Attributes[j].Value
              this.user_set.add(data.Users[i].Attributes[j].Value)
            }
            if (data.Users[i].Attributes[j].Name == "email") {
              single_user.email = data.Users[i].Attributes[j].Value
            }
            if (single_user.email != undefined && single_user.username != undefined) {
              break;
            }
          }
          this.included_user_list.push(single_user)
          this.email_map[single_user.email] = single_user.username
        }

        // Paginate if necessary
        if (data.NextToken != undefined) {
          this.get_included_users(client, data.NextToken)
        } else {
          this.excluded_user_list = []
          this.get_excluded_users(client);
        }
      })
    },
    get_excluded_users(client, paginationToken=undefined) {
      // Get list of all users not in the selected group
      const input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        PaginationToken: paginationToken
      }
      const command = new ListUsersCommand(input);
      client.send(command).then(data => {
        for (let i = 0; i < data.Users.length; i++) {
          let single_user = {};
          for (let j = 0; j < data.Users[i].Attributes.length; j++) {
            if (data.Users[i].Attributes[j].Name == "sub") {
              // Ignore the user if it is in the group
              if (this.user_set.has(data.Users[i].Attributes[j].Value)) {
                break;
              } else {
                single_user.username = data.Users[i].Attributes[j].Value
              }
            } 
            if (data.Users[i].Attributes[j].Name == "email") {
              single_user.email = data.Users[i].Attributes[j].Value
            }
            // Breakout to save time
            if (single_user.email != undefined && single_user.username != undefined) {
              break;
            }
          }
          // Only push if the user was not in the group
          if (single_user.username != undefined) {
            this.excluded_user_list.push(single_user)
            this.email_map[single_user.email] = single_user.username
          }
        }
        // Paginate if necessary
        if (data.PaginationToken != undefined) {
          this.get_excluded_users(client, data.PaginationToken)
        }
      })
    },
    save() {
      // Register a new client
      let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
      const client = new CognitoIdentityProviderClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
          }
        })
      });

      // Add user to group
      for (let i = 0; i < this.selected_users_add.length; i++) {
        var input = {
          UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
          GroupName: this.group,
          Username: this.email_map[this.selected_users_add[i]]
        }
        const command = new AdminAddUserToGroupCommand(input);
        client.send(command).catch(err => {
          // console.error(err) // DEBUG
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        })
      }
      
      // Remove user from group
      for (let i = 0; i < this.selected_users_remove.length; i++) {
        var input = {
          UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
          GroupName: this.group,
          Username: this.email_map[this.selected_users_remove[i]]
        }
        const command = new AdminRemoveUserFromGroupCommand(input);
        client.send(command).catch(err => {
          // console.error(err) // DEBUG
          const notification = {
            type: 'error',
            message: err.message
          }
          this.$store.dispatch('addNotification', notification)
        })
      }
      this.reset()
    },
    toggle_delete() {
      this.enableDelete = !this.enableDelete
    },
    delete_group() {
      // Register a new client
      let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
      const client = new CognitoIdentityProviderClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
          }
        })
      });
      
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        GroupName: this.group
      }
      const command = new DeleteGroupCommand(input);
      client.send(command).catch(err => {
        // console.error(err) // DEBUG
        const notification = {
          type: 'error',
          message: err.message
        }
        this.$store.dispatch('addNotification', notification)
      })
      this.reset()
    },
    reset() {
      // Reset state
      this.group = null
      const notification = {
        type: 'success',
        message: "Changes made successfully"
      }
      this.$store.dispatch('addNotification', notification)
    }
  }
}
</script>

<style scoped>
.slide-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
</style>
