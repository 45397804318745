import gql from "graphql-tag";

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = gql`
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      ownerName
      projects {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = gql`
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      ownerName
      projects {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = gql`
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      ownerName
      projects {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProject = gql`
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectName
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      phyloTreeProjects {
        nextToken
      }
      comparisonProjects {
        nextToken
      }
      phredProjects {
        nextToken
      }
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = gql`
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectName
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      phyloTreeProjects {
        nextToken
      }
      comparisonProjects {
        nextToken
      }
      phredProjects {
        nextToken
      }
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = gql`
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectName
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      phyloTreeProjects {
        nextToken
      }
      comparisonProjects {
        nextToken
      }
      phredProjects {
        nextToken
      }
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const createPhyloTreeSubProject = gql`
  mutation CreatePhyloTreeSubProject(
    $input: CreatePhyloTreeSubProjectInput!
    $condition: ModelPhyloTreeSubProjectConditionInput
  ) {
    createPhyloTreeSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      alignLabels
      bacterialGroup
      branchLengths
      circular
      displayBackgroundColor
      displayClostridiumPathogens
      displayGenomeLength
      displayInnerNodes
      displayLeafLabels
      displayLeaves
      displayLegend
      displayOrder
      rings
      showMethanoGenus
      showOutgroup
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const updatePhyloTreeSubProject = gql`
  mutation UpdatePhyloTreeSubProject(
    $input: UpdatePhyloTreeSubProjectInput!
    $condition: ModelPhyloTreeSubProjectConditionInput
  ) {
    updatePhyloTreeSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      alignLabels
      bacterialGroup
      branchLengths
      circular
      displayBackgroundColor
      displayClostridiumPathogens
      displayGenomeLength
      displayInnerNodes
      displayLeafLabels
      displayLeaves
      displayLegend
      displayOrder
      rings
      showMethanoGenus
      showOutgroup
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const deletePhyloTreeSubProject = gql`
  mutation DeletePhyloTreeSubProject(
    $input: DeletePhyloTreeSubProjectInput!
    $condition: ModelPhyloTreeSubProjectConditionInput
  ) {
    deletePhyloTreeSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      alignLabels
      bacterialGroup
      branchLengths
      circular
      displayBackgroundColor
      displayClostridiumPathogens
      displayGenomeLength
      displayInnerNodes
      displayLeafLabels
      displayLeaves
      displayLegend
      displayOrder
      rings
      showMethanoGenus
      showOutgroup
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const createComparisonSubProject = gql`
  mutation CreateComparisonSubProject(
    $input: CreateComparisonSubProjectInput!
    $condition: ModelComparisonSubProjectConditionInput
  ) {
    createComparisonSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      genes
      organisms
      charts {
        nextToken
      }
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const updateComparisonSubProject = gql`
  mutation UpdateComparisonSubProject(
    $input: UpdateComparisonSubProjectInput!
    $condition: ModelComparisonSubProjectConditionInput
  ) {
    updateComparisonSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      genes
      organisms
      charts {
        nextToken
      }
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const deleteComparisonSubProject = gql`
  mutation DeleteComparisonSubProject(
    $input: DeleteComparisonSubProjectInput!
    $condition: ModelComparisonSubProjectConditionInput
  ) {
    deleteComparisonSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      genes
      organisms
      charts {
        nextToken
      }
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const createComparisonChart = gql`
  mutation CreateComparisonChart(
    $input: CreateComparisonChartInput!
    $condition: ModelComparisonChartConditionInput
  ) {
    createComparisonChart(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      subProjectId
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      subProject {
        id
        owner
        userId
        projectId
        name
        genes
        organisms
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      chartType
      colour
      parameters
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const updateComparisonChart = gql`
  mutation UpdateComparisonChart(
    $input: UpdateComparisonChartInput!
    $condition: ModelComparisonChartConditionInput
  ) {
    updateComparisonChart(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      subProjectId
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      subProject {
        id
        owner
        userId
        projectId
        name
        genes
        organisms
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      chartType
      colour
      parameters
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const deleteComparisonChart = gql`
  mutation DeleteComparisonChart(
    $input: DeleteComparisonChartInput!
    $condition: ModelComparisonChartConditionInput
  ) {
    deleteComparisonChart(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      subProjectId
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      subProject {
        id
        owner
        userId
        projectId
        name
        genes
        organisms
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      chartType
      colour
      parameters
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const createPhredSubProject = gql`
  mutation CreatePhredSubProject(
    $input: CreatePhredSubProjectInput!
    $condition: ModelPhredSubProjectConditionInput
  ) {
    createPhredSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      search
      filterFamily
      filterReceptorLocation
      filterDifferentialStain
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const updatePhredSubProject = gql`
  mutation UpdatePhredSubProject(
    $input: UpdatePhredSubProjectInput!
    $condition: ModelPhredSubProjectConditionInput
  ) {
    updatePhredSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      search
      filterFamily
      filterReceptorLocation
      filterDifferentialStain
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const deletePhredSubProject = gql`
  mutation DeletePhredSubProject(
    $input: DeletePhredSubProjectInput!
    $condition: ModelPhredSubProjectConditionInput
  ) {
    deletePhredSubProject(input: $input, condition: $condition) {
      id
      owner
      userId
      projectId
      name
      user {
        id
        owner
        ownerName
        createdAt
        updatedAt
      }
      project {
        id
        owner
        userId
        projectName
        sharedGroups
        sharedUsers
        createdAt
        updatedAt
      }
      search
      filterFamily
      filterReceptorLocation
      filterDifferentialStain
      sharedGroups
      sharedUsers
      createdAt
      updatedAt
    }
  }
`;
export const createPhageFamily = gql`
  mutation CreatePhageFamily(
    $input: CreatePhageFamilyInput!
    $condition: ModelPhageFamilyConditionInput
  ) {
    createPhageFamily(input: $input, condition: $condition) {
      id
      name
      imageLocationInS3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePhageFamily = gql`
  mutation UpdatePhageFamily(
    $input: UpdatePhageFamilyInput!
    $condition: ModelPhageFamilyConditionInput
  ) {
    updatePhageFamily(input: $input, condition: $condition) {
      id
      name
      imageLocationInS3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePhageFamily = gql`
  mutation DeletePhageFamily(
    $input: DeletePhageFamilyInput!
    $condition: ModelPhageFamilyConditionInput
  ) {
    deletePhageFamily(input: $input, condition: $condition) {
      id
      name
      imageLocationInS3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGramStain = gql`
  mutation CreateGramStain(
    $input: CreateGramStainInput!
    $condition: ModelGramStainConditionInput
  ) {
    createGramStain(input: $input, condition: $condition) {
      id
      name
      imageLocationInS3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGramStain = gql`
  mutation UpdateGramStain(
    $input: UpdateGramStainInput!
    $condition: ModelGramStainConditionInput
  ) {
    updateGramStain(input: $input, condition: $condition) {
      id
      name
      imageLocationInS3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGramStain = gql`
  mutation DeleteGramStain(
    $input: DeleteGramStainInput!
    $condition: ModelGramStainConditionInput
  ) {
    deleteGramStain(input: $input, condition: $condition) {
      id
      name
      imageLocationInS3
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createReceptorLocation = gql`
  mutation CreateReceptorLocation(
    $input: CreateReceptorLocationInput!
    $condition: ModelReceptorLocationConditionInput
  ) {
    createReceptorLocation(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateReceptorLocation = gql`
  mutation UpdateReceptorLocation(
    $input: UpdateReceptorLocationInput!
    $condition: ModelReceptorLocationConditionInput
  ) {
    updateReceptorLocation(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteReceptorLocation = gql`
  mutation DeleteReceptorLocation(
    $input: DeleteReceptorLocationInput!
    $condition: ModelReceptorLocationConditionInput
  ) {
    deleteReceptorLocation(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBacterialHost = gql`
  mutation CreateBacterialHost(
    $input: CreateBacterialHostInput!
    $condition: ModelBacterialHostConditionInput
  ) {
    createBacterialHost(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBacterialHost = gql`
  mutation UpdateBacterialHost(
    $input: UpdateBacterialHostInput!
    $condition: ModelBacterialHostConditionInput
  ) {
    updateBacterialHost(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBacterialHost = gql`
  mutation DeleteBacterialHost(
    $input: DeleteBacterialHostInput!
    $condition: ModelBacterialHostConditionInput
  ) {
    deleteBacterialHost(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPhage = gql`
  mutation CreatePhage(
    $input: CreatePhageInput!
    $condition: ModelPhageConditionInput
  ) {
    createPhage(input: $input, condition: $condition) {
      phage
      owner
      alias
      comments
      family
      gramStain
      mainHost
      receptorLocation
      receptors
      receptorKeywords
      references
      verified
      doi
      createdAt
      updatedAt
    }
  }
`;
export const updatePhage = gql`
  mutation UpdatePhage(
    $input: UpdatePhageInput!
    $condition: ModelPhageConditionInput
  ) {
    updatePhage(input: $input, condition: $condition) {
      phage
      owner
      alias
      comments
      family
      gramStain
      mainHost
      receptorLocation
      receptors
      receptorKeywords
      references
      verified
      doi
      createdAt
      updatedAt
    }
  }
`;
export const deletePhage = gql`
  mutation DeletePhage(
    $input: DeletePhageInput!
    $condition: ModelPhageConditionInput
  ) {
    deletePhage(input: $input, condition: $condition) {
      phage
      owner
      alias
      comments
      family
      gramStain
      mainHost
      receptorLocation
      receptors
      receptorKeywords
      references
      verified
      doi
      createdAt
      updatedAt
    }
  }
`;
export const createPhyloTree = gql`
  mutation CreatePhyloTree(
    $input: CreatePhyloTreeInput!
    $condition: ModelPhyloTreeConditionInput
  ) {
    createPhyloTree(input: $input, condition: $condition) {
      name
      owner
      tree
      organisms {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePhyloTree = gql`
  mutation UpdatePhyloTree(
    $input: UpdatePhyloTreeInput!
    $condition: ModelPhyloTreeConditionInput
  ) {
    updatePhyloTree(input: $input, condition: $condition) {
      name
      owner
      tree
      organisms {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePhyloTree = gql`
  mutation DeletePhyloTree(
    $input: DeletePhyloTreeInput!
    $condition: ModelPhyloTreeConditionInput
  ) {
    deletePhyloTree(input: $input, condition: $condition) {
      name
      owner
      tree
      organisms {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPhyloTreeOrganism = gql`
  mutation CreatePhyloTreeOrganism(
    $input: CreatePhyloTreeOrganismInput!
    $condition: ModelPhyloTreeOrganismConditionInput
  ) {
    createPhyloTreeOrganism(input: $input, condition: $condition) {
      index
      name
      owner
      phyloTree
      id
      createdAt
      updatedAt
    }
  }
`;
export const updatePhyloTreeOrganism = gql`
  mutation UpdatePhyloTreeOrganism(
    $input: UpdatePhyloTreeOrganismInput!
    $condition: ModelPhyloTreeOrganismConditionInput
  ) {
    updatePhyloTreeOrganism(input: $input, condition: $condition) {
      index
      name
      owner
      phyloTree
      id
      createdAt
      updatedAt
    }
  }
`;
export const deletePhyloTreeOrganism = gql`
  mutation DeletePhyloTreeOrganism(
    $input: DeletePhyloTreeOrganismInput!
    $condition: ModelPhyloTreeOrganismConditionInput
  ) {
    deletePhyloTreeOrganism(input: $input, condition: $condition) {
      index
      name
      owner
      phyloTree
      id
      createdAt
      updatedAt
    }
  }
`;
export const createPhyloTreeMetaData = gql`
  mutation CreatePhyloTreeMetaData(
    $input: CreatePhyloTreeMetaDataInput!
    $condition: ModelPhyloTreeMetaDataConditionInput
  ) {
    createPhyloTreeMetaData(input: $input, condition: $condition) {
      name
      index
      phylotree
      family
      genus
      genomeLength
      numberOfCodingSeq
      GC
      enzymes
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePhyloTreeMetaData = gql`
  mutation UpdatePhyloTreeMetaData(
    $input: UpdatePhyloTreeMetaDataInput!
    $condition: ModelPhyloTreeMetaDataConditionInput
  ) {
    updatePhyloTreeMetaData(input: $input, condition: $condition) {
      name
      index
      phylotree
      family
      genus
      genomeLength
      numberOfCodingSeq
      GC
      enzymes
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePhyloTreeMetaData = gql`
  mutation DeletePhyloTreeMetaData(
    $input: DeletePhyloTreeMetaDataInput!
    $condition: ModelPhyloTreeMetaDataConditionInput
  ) {
    deletePhyloTreeMetaData(input: $input, condition: $condition) {
      name
      index
      phylotree
      family
      genus
      genomeLength
      numberOfCodingSeq
      GC
      enzymes
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createEnzymes = gql`
  mutation CreateEnzymes(
    $input: CreateEnzymesInput!
    $condition: ModelEnzymesConditionInput
  ) {
    createEnzymes(input: $input, condition: $condition) {
      pathway
      enzymes
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEnzymes = gql`
  mutation UpdateEnzymes(
    $input: UpdateEnzymesInput!
    $condition: ModelEnzymesConditionInput
  ) {
    updateEnzymes(input: $input, condition: $condition) {
      pathway
      enzymes
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEnzymes = gql`
  mutation DeleteEnzymes(
    $input: DeleteEnzymesInput!
    $condition: ModelEnzymesConditionInput
  ) {
    deleteEnzymes(input: $input, condition: $condition) {
      pathway
      enzymes
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createKendraAbstracts = gql`
  mutation CreateKendraAbstracts(
    $input: CreateKendraAbstractsInput!
    $condition: ModelKendraAbstractsConditionInput
  ) {
    createKendraAbstracts(input: $input, condition: $condition) {
      title
      owner
      abstract
      affiliations
      articleNumber
      authors
      authorsWithAffiliations
      doi
      documentType
      fundingDetails
      issue
      keywords
      publisher
      rawTitle
      references
      sourceTitle
      volume
      year
      createdAt
      updatedAt
    }
  }
`;
export const updateKendraAbstracts = gql`
  mutation UpdateKendraAbstracts(
    $input: UpdateKendraAbstractsInput!
    $condition: ModelKendraAbstractsConditionInput
  ) {
    updateKendraAbstracts(input: $input, condition: $condition) {
      title
      owner
      abstract
      affiliations
      articleNumber
      authors
      authorsWithAffiliations
      doi
      documentType
      fundingDetails
      issue
      keywords
      publisher
      rawTitle
      references
      sourceTitle
      volume
      year
      createdAt
      updatedAt
    }
  }
`;
export const deleteKendraAbstracts = gql`
  mutation DeleteKendraAbstracts(
    $input: DeleteKendraAbstractsInput!
    $condition: ModelKendraAbstractsConditionInput
  ) {
    deleteKendraAbstracts(input: $input, condition: $condition) {
      title
      owner
      abstract
      affiliations
      articleNumber
      authors
      authorsWithAffiliations
      doi
      documentType
      fundingDetails
      issue
      keywords
      publisher
      rawTitle
      references
      sourceTitle
      volume
      year
      createdAt
      updatedAt
    }
  }
`;
export const createPhredSubmission = gql`
  mutation CreatePhredSubmission(
    $input: CreatePhredSubmissionInput!
    $condition: ModelPhredSubmissionConditionInput
  ) {
    createPhredSubmission(input: $input, condition: $condition) {
      phage
      owner
      alias
      cognitoUsername
      comments
      family
      gramStain
      mainHost
      receptorLocation
      receptors
      references
      verified
      createdAt
      updatedAt
    }
  }
`;
export const updatePhredSubmission = gql`
  mutation UpdatePhredSubmission(
    $input: UpdatePhredSubmissionInput!
    $condition: ModelPhredSubmissionConditionInput
  ) {
    updatePhredSubmission(input: $input, condition: $condition) {
      phage
      owner
      alias
      cognitoUsername
      comments
      family
      gramStain
      mainHost
      receptorLocation
      receptors
      references
      verified
      createdAt
      updatedAt
    }
  }
`;
export const deletePhredSubmission = gql`
  mutation DeletePhredSubmission(
    $input: DeletePhredSubmissionInput!
    $condition: ModelPhredSubmissionConditionInput
  ) {
    deletePhredSubmission(input: $input, condition: $condition) {
      phage
      owner
      alias
      cognitoUsername
      comments
      family
      gramStain
      mainHost
      receptorLocation
      receptors
      references
      verified
      createdAt
      updatedAt
    }
  }
`;
export const createPhredIssue = gql`
  mutation CreatePhredIssue(
    $input: CreatePhredIssueInput!
    $condition: ModelPhredIssueConditionInput
  ) {
    createPhredIssue(input: $input, condition: $condition) {
      id
      owner
      date
      email
      title
      issue
      createdAt
      updatedAt
    }
  }
`;
export const updatePhredIssue = gql`
  mutation UpdatePhredIssue(
    $input: UpdatePhredIssueInput!
    $condition: ModelPhredIssueConditionInput
  ) {
    updatePhredIssue(input: $input, condition: $condition) {
      id
      owner
      date
      email
      title
      issue
      createdAt
      updatedAt
    }
  }
`;
export const deletePhredIssue = gql`
  mutation DeletePhredIssue(
    $input: DeletePhredIssueInput!
    $condition: ModelPhredIssueConditionInput
  ) {
    deletePhredIssue(input: $input, condition: $condition) {
      id
      owner
      date
      email
      title
      issue
      createdAt
      updatedAt
    }
  }
`;
export const createOrganism = gql`
  mutation CreateOrganism(
    $input: CreateOrganismInput!
    $condition: ModelOrganismConditionInput
  ) {
    createOrganism(input: $input, condition: $condition) {
      name
      owner
      family
      source
      country
      date
      etymology
      typeStrains
      rRNAGene16S
      rRNAGene16SDownloadLink
      rRNAGene16SNCBILink
      effectivePublication
      effectivePublicationDOI
      riskGroup
      sequenceLink
      genes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganism = gql`
  mutation UpdateOrganism(
    $input: UpdateOrganismInput!
    $condition: ModelOrganismConditionInput
  ) {
    updateOrganism(input: $input, condition: $condition) {
      name
      owner
      family
      source
      country
      date
      etymology
      typeStrains
      rRNAGene16S
      rRNAGene16SDownloadLink
      rRNAGene16SNCBILink
      effectivePublication
      effectivePublicationDOI
      riskGroup
      sequenceLink
      genes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganism = gql`
  mutation DeleteOrganism(
    $input: DeleteOrganismInput!
    $condition: ModelOrganismConditionInput
  ) {
    deleteOrganism(input: $input, condition: $condition) {
      name
      owner
      family
      source
      country
      date
      etymology
      typeStrains
      rRNAGene16S
      rRNAGene16SDownloadLink
      rRNAGene16SNCBILink
      effectivePublication
      effectivePublicationDOI
      riskGroup
      sequenceLink
      genes {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGene = gql`
  mutation CreateGene(
    $input: CreateGeneInput!
    $condition: ModelGeneConditionInput
  ) {
    createGene(input: $input, condition: $condition) {
      id
      owner
      organismName
      organism {
        name
        owner
        family
        source
        country
        date
        etymology
        typeStrains
        rRNAGene16S
        rRNAGene16SDownloadLink
        rRNAGene16SNCBILink
        effectivePublication
        effectivePublicationDOI
        riskGroup
        sequenceLink
        createdAt
        updatedAt
      }
      preferredName
      dnaSequence
      proteinSequence
      subGenomeLabel
      startIndex
      endIndex
      cogCategory
      descriptionFunction
      createdAt
      updatedAt
    }
  }
`;
export const updateGene = gql`
  mutation UpdateGene(
    $input: UpdateGeneInput!
    $condition: ModelGeneConditionInput
  ) {
    updateGene(input: $input, condition: $condition) {
      id
      owner
      organismName
      organism {
        name
        owner
        family
        source
        country
        date
        etymology
        typeStrains
        rRNAGene16S
        rRNAGene16SDownloadLink
        rRNAGene16SNCBILink
        effectivePublication
        effectivePublicationDOI
        riskGroup
        sequenceLink
        createdAt
        updatedAt
      }
      preferredName
      dnaSequence
      proteinSequence
      subGenomeLabel
      startIndex
      endIndex
      cogCategory
      descriptionFunction
      createdAt
      updatedAt
    }
  }
`;
export const deleteGene = gql`
  mutation DeleteGene(
    $input: DeleteGeneInput!
    $condition: ModelGeneConditionInput
  ) {
    deleteGene(input: $input, condition: $condition) {
      id
      owner
      organismName
      organism {
        name
        owner
        family
        source
        country
        date
        etymology
        typeStrains
        rRNAGene16S
        rRNAGene16SDownloadLink
        rRNAGene16SNCBILink
        effectivePublication
        effectivePublicationDOI
        riskGroup
        sequenceLink
        createdAt
        updatedAt
      }
      preferredName
      dnaSequence
      proteinSequence
      subGenomeLabel
      startIndex
      endIndex
      cogCategory
      descriptionFunction
      createdAt
      updatedAt
    }
  }
`;
export const createGenomeReference = gql`
  mutation CreateGenomeReference(
    $input: CreateGenomeReferenceInput!
    $condition: ModelGenomeReferenceConditionInput
  ) {
    createGenomeReference(input: $input, condition: $condition) {
      id
      name
      faa
      fasta
      fna
      gff
      url
      strain
      etymology
      onesixsRNAGene
      fastaDownload
      NCBILink
      effectivePublication
      doi
      pubmed
      riskGroup
      completeness
      contamination
      typeStrains
      isLPSN
      eggNOGGeneFunctions {
        nextToken
      }
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const updateGenomeReference = gql`
  mutation UpdateGenomeReference(
    $input: UpdateGenomeReferenceInput!
    $condition: ModelGenomeReferenceConditionInput
  ) {
    updateGenomeReference(input: $input, condition: $condition) {
      id
      name
      faa
      fasta
      fna
      gff
      url
      strain
      etymology
      onesixsRNAGene
      fastaDownload
      NCBILink
      effectivePublication
      doi
      pubmed
      riskGroup
      completeness
      contamination
      typeStrains
      isLPSN
      eggNOGGeneFunctions {
        nextToken
      }
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const deleteGenomeReference = gql`
  mutation DeleteGenomeReference(
    $input: DeleteGenomeReferenceInput!
    $condition: ModelGenomeReferenceConditionInput
  ) {
    deleteGenomeReference(input: $input, condition: $condition) {
      id
      name
      faa
      fasta
      fna
      gff
      url
      strain
      etymology
      onesixsRNAGene
      fastaDownload
      NCBILink
      effectivePublication
      doi
      pubmed
      riskGroup
      completeness
      contamination
      typeStrains
      isLPSN
      eggNOGGeneFunctions {
        nextToken
      }
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const createEggNOGGeneFunction = gql`
  mutation CreateEggNOGGeneFunction(
    $input: CreateEggNOGGeneFunctionInput!
    $condition: ModelEggNOGGeneFunctionConditionInput
  ) {
    createEggNOGGeneFunction(input: $input, condition: $condition) {
      id
      genome_reference_id
      query
      cogCategory
      description
      preferredName
      GOs
      EC
      keggKO
      keggPathway
      keggModule
      keggReaction
      keggRClass
      BRITE
      CAZy
      biggReaction
      PFAMs
      versionNum
      genomeReference {
        id
        name
        faa
        fasta
        fna
        gff
        url
        strain
        etymology
        onesixsRNAGene
        fastaDownload
        NCBILink
        effectivePublication
        doi
        pubmed
        riskGroup
        completeness
        contamination
        typeStrains
        isLPSN
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const updateEggNOGGeneFunction = gql`
  mutation UpdateEggNOGGeneFunction(
    $input: UpdateEggNOGGeneFunctionInput!
    $condition: ModelEggNOGGeneFunctionConditionInput
  ) {
    updateEggNOGGeneFunction(input: $input, condition: $condition) {
      id
      genome_reference_id
      query
      cogCategory
      description
      preferredName
      GOs
      EC
      keggKO
      keggPathway
      keggModule
      keggReaction
      keggRClass
      BRITE
      CAZy
      biggReaction
      PFAMs
      versionNum
      genomeReference {
        id
        name
        faa
        fasta
        fna
        gff
        url
        strain
        etymology
        onesixsRNAGene
        fastaDownload
        NCBILink
        effectivePublication
        doi
        pubmed
        riskGroup
        completeness
        contamination
        typeStrains
        isLPSN
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const deleteEggNOGGeneFunction = gql`
  mutation DeleteEggNOGGeneFunction(
    $input: DeleteEggNOGGeneFunctionInput!
    $condition: ModelEggNOGGeneFunctionConditionInput
  ) {
    deleteEggNOGGeneFunction(input: $input, condition: $condition) {
      id
      genome_reference_id
      query
      cogCategory
      description
      preferredName
      GOs
      EC
      keggKO
      keggPathway
      keggModule
      keggReaction
      keggRClass
      BRITE
      CAZy
      biggReaction
      PFAMs
      versionNum
      genomeReference {
        id
        name
        faa
        fasta
        fna
        gff
        url
        strain
        etymology
        onesixsRNAGene
        fastaDownload
        NCBILink
        effectivePublication
        doi
        pubmed
        riskGroup
        completeness
        contamination
        typeStrains
        isLPSN
        createdAt
        updatedAt
        owner
        sharedUsers
      }
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const createEnzymeDialogData = gql`
  mutation CreateEnzymeDialogData(
    $input: CreateEnzymeDialogDataInput!
    $condition: ModelEnzymeDialogDataConditionInput
  ) {
    createEnzymeDialogData(input: $input, condition: $condition) {
      id
      fullName
      otherNames
      class
      shortName
      ECNumber
      KO
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const updateEnzymeDialogData = gql`
  mutation UpdateEnzymeDialogData(
    $input: UpdateEnzymeDialogDataInput!
    $condition: ModelEnzymeDialogDataConditionInput
  ) {
    updateEnzymeDialogData(input: $input, condition: $condition) {
      id
      fullName
      otherNames
      class
      shortName
      ECNumber
      KO
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
export const deleteEnzymeDialogData = gql`
  mutation DeleteEnzymeDialogData(
    $input: DeleteEnzymeDialogDataInput!
    $condition: ModelEnzymeDialogDataConditionInput
  ) {
    deleteEnzymeDialogData(input: $input, condition: $condition) {
      id
      fullName
      otherNames
      class
      shortName
      ECNumber
      KO
      createdAt
      updatedAt
      owner
      sharedUsers
    }
  }
`;
