<template>
  <v-card class="mb-n2">
    <v-row class="mt-3 pa-10">
      <v-col>
        <v-row>
          <v-card-title> Features Enabled for Public </v-card-title>
        </v-row>
        <v-row
          v-for="featureFlag in featureFlagsFromDatabase"
          :key="featureFlag.name"
        >
          <v-checkbox
            v-model="featureFlag.enabled"
            :label="featureFlag.name"
          ></v-checkbox>
        </v-row>
        <v-row>
          <v-btn @click="confirmationModal = true">Change feature flag</v-btn>
        </v-row>
        <v-dialog v-model="confirmationModal" width="300">
          <Dialog
            @close-dialog="confirmationModal = false"
            heading="Changing Production Environment"
          >
            <v-card-text>
              Are you sure you want to change feature flags in the production
              and development environments?
            </v-card-text>
            <v-btn color="error" @click="changeFeatureFlag">
              Confirm Change
            </v-btn>
          </Dialog>
        </v-dialog>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {
  DynamoDBClient,
  ScanCommand,
  BatchWriteItemCommand,
} from '@aws-sdk/client-dynamodb';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import Dialog from '@/components/Dialog';

const REGION = 'us-west-2';

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      featureFlagsFromDatabase: [],
      ddbClient: null,
      confirmationModal: false,
    };
  },
  methods: {
    async changeFeatureFlag() {
      this.confirmationModal = false;
      const requestBody = this.featureFlagsFromDatabase.map((item) => {
        const itemToPut = {};
        itemToPut['feature-name'] = { S: item.name };
        itemToPut['disabled'] = { N: item.enabled ? '0' : '1' };

        return {
          PutRequest: {
            Item: itemToPut,
          },
        };
      });

      const params = {
        RequestItems: {
          'feature-flag': requestBody,
        },
      };

      try {
        // update all rows in the database
        const command = new BatchWriteItemCommand(params);
        await this.ddbClient.send(command);

        const notification = {
          type: 'success',
          message: `Updated all feature flags`,
        };

        this.$store.dispatch('addNotification', notification);
      } catch (e) {
        console.error(e);

        // if we have an error then show a notification
        const notification = {
          type: 'error',
          message: `Could not update feature flag`,
        };

        this.$store.dispatch('addNotification', notification);
      }
    },
  },
  async created() {
    let cognitoProviderName =
      'cognito-idp.' +
      REGION +
      '.amazonaws.com/' +
      process.env.VUE_APP_COGNITO_POOL_ID;

    try {
      // Create an Amazon DynamoDB service client object.
      this.ddbClient = new DynamoDBClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_IMAGE,
          logins: {
            [`${cognitoProviderName}`]:
              this.$store.state.user.session.idToken.jwtToken,
          },
        }),
      });

      // get all items from the table feature-flag
      const command = new ScanCommand({ TableName: 'feature-flag' });
      const response = await this.ddbClient.send(command);
      this.featureFlagsFromDatabase = response.Items.map((item) => ({
        name: item['feature-name'].S,
        enabled: item.disabled.N !== '1',
      }));
    } catch (e) {
      console.error(e);

      // if we have an error then show a notification
      const notification = {
        type: 'error',
        message: `Could not get data for restricted access`,
      };

      this.$store.dispatch('addNotification', notification);
    }
  },
};
</script>
