<template>
  <v-card class="mb-n2">
    <v-row class="mt-3">
      <v-card-title class="tour-phred-title ml-5">
        List of Users
      </v-card-title>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="user_list"
      :search="search"
      :loading="user_list.length === 0 ? true : false"
      loading-text="Loading users... Please wait"
      item-key="username"
    ></v-data-table>
  </v-card>
</template>

<script>
import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
  AdminListGroupsForUserCommand
} from "@aws-sdk/client-cognito-identity-provider";

const { fromCognitoIdentityPool } = require('@aws-sdk/credential-provider-cognito-identity')
const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity')
const REGION = "us-west-2";

export default {
  data() {
    return {
      // List of user objects
      user_list: [],
      headers: [
        {
          text: 'Username',
          align: 'start',
          sortable: true,
          value: 'username'
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Institution',
          align: 'start',
          sortable: true,
          value: 'institution'
        },
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email'
        },
        {
          text: 'Groups',
          align: 'start',
          sortable: true,
          value: 'groups'
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: true,
          value: 'date_created'
        }
      ]
    }
  },
  props: [
    'search'
  ],
  created() {
    this.get_users()
  },
  methods: {
    get_users(client=null, paginationToken=undefined) {
      if (client === null) {
        // Register a new client
        let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
        var client = new CognitoIdentityProviderClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
            logins: {
              [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
            }
          })
        });
      }

      // Get list of all users
      const input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        PaginationToken: paginationToken
      }
      const command = new ListUsersCommand(input);
      client.send(command).then(data => {
        for (let i = 0; i < data.Users.length; i++) {
          let single_user = {};
          for (let j = 0; j < data.Users[i].Attributes.length; j++) {
            if (data.Users[i].Attributes[j].Name == "sub") {
              single_user.username = data.Users[i].Attributes[j].Value
            } 
            if (data.Users[i].Attributes[j].Name == "email") {
              single_user.email = data.Users[i].Attributes[j].Value
            }
            if (data.Users[i].Attributes[j].Name == "name") {
              single_user.name = data.Users[i].Attributes[j].Value
            }
            if (data.Users[i].Attributes[j].Name == "custom:organization") {
              single_user.institution = data.Users[i].Attributes[j].Value
            }
          }
          single_user.date_created = data.Users[i].UserCreateDate

          // Must get user groups and THEN push the user
          this.get_user_groups(client, single_user)
        }
        
        // Paginate if necessary
        if (data.PaginationToken != undefined) {
          this.get_users(client, data.PaginationToken)
        }
      })
    },
    get_user_groups(client, single_user, nextToken=undefined) {
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        Username: single_user.username,
        NextToken: nextToken
      }
      var command = new AdminListGroupsForUserCommand(input);
      client.send(command).then(data => {
        single_user.groups = []
        for (let i = 0; i < data.Groups.length; i++) {
          single_user.groups.push(data.Groups[i].GroupName)
        }
        this.user_list.push(single_user)

        // Paginate if necessary
        if (data.NextToken != undefined) {
          this.get_user_groups(client, single_user, data.NextToken)
        }
      }).catch(err => {
        // console.error(err) // DEBUG
        const notification = {
          type: 'error',
          message: err.message
        }
        this.$store.dispatch('addNotification', notification)
      })
    }
  },
}
</script>

<style scoped>
.slide-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
</style>
