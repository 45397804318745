<template>
  <v-card
    class="my-4 tour-phred-submission"
    v-if="!isSelected"
  >
    <v-card-title>Phage Receptor Database (PhReD)</v-card-title>
    <v-col>
      <v-text-field
        v-model="search_submission"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        class="mt-n3 mb-2"
      ></v-text-field>
    </v-col>
    <v-data-table
      :search="search_submission"
      v-show="
        listPhages.items && listPhages.items.length > 0
      "
      :headers="phredHeaders"
      :items="phredContent"
      item-key="phage"
      class="elevation-1"
      v-model="selected"
      show-expand
      single-select
      show-select
      :loading="listPhages.length === 0 ? true : false"
      loading-text="Loading phages..."
      :footer-props="{ itemsPerPageOptions: [10, 50, 100, -1] }"
      @pagination="
        (e) => {
          itemCount = e.itemsLength
        }
      "
      @current-items="
        (e) => {
          currentItems = e
        }
      "
      :key="renderKey"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p class="my-2">References: {{ item.references }}</p>
          <p v-show="!!item.comments && item.comments !== ' '">
            Comments: {{ item.comments }}
          </p>
        </td>
      </template>
      <template v-slot:[`item.gramStain`]="{ item }">
        <p :class="getColor(item.gramStain)" class="my-1">
          {{ item.gramStain }}
        </p>
      </template>
      <template v-slot:[`item.verified`]="{ item }">
        <p :class="getColor(item.verified)" class="my-1">
          {{ item.verified }}
        </p>
      </template>
    </v-data-table>
  </v-card>
  <ManagePhredSubmissionEditor
    v-else-if="isSelected"
    v-on:cancel="cancel"
    :submission="this.selectedPhage"
  />
</template>

<script>
import { listPhages } from '@/graphql/queries.js'
import { deletePhredSubmission } from '@/graphql/mutations.js'
import {
  wordToEnum,
  enumToWord,
  enumToCamel
} from '@/utilities/dataPreprocess.js'
import ManagePhredSubmissionEditor from './ManagePhredSubmissionEditor'

export default {
  computed: {
    phredHeaders() {
      let data = [
        {
          text: 'Phage',
          value: 'phage'
        },
        {
          text: 'Family',
          value: 'family'
        },
        {
          text: 'Main host',
          value: 'mainHost'
        },
        {
          text: 'Differential stain',
          value: 'gramStain'
        },
        {
          text: 'Location of receptor',
          value: 'receptorLocation'
        },
        {
          text: 'Receptor(s)',
          value: 'receptors'
        },
        {
          text: 'Verified',
          value: 'verified'
        },
        {
          text: 'References',
          value: 'references',
          align: ' d-none'
        },
        {
          text: 'Alias',
          value: 'alias',
          align: ' d-none'
        },
        { text: 'References/Comments', value: 'data-table-expand' }
      ]
      return data
    },
    phredContent() {
      let table = this.listPhages.items
      if (!!table) {
        table.forEach((item, i) => {
          table[i].family = this.enumToCamel(item.family)
          table[i].gramStain = this.enumToWord(item.gramStain)
        })
      }
      return table
    }
  },
  data() {
    return {
      // data
      listPhages: [],
      search_submission: '',
      editedIndex: -1,
      editedItem: {
        Phage: '',
        Family: '',
        Main_host: '',
        Gram_stain: '',
        Receptor_location: '',
        Receptors: '',
        References: '',
        Comments: ''
      },
      defaultItem: {
        Phage: '',
        Family: '',
        Main_host: '',
        Gram_stain: '',
        Receptor_location: '',
        Receptors: '',
        References: '',
        Comments: ''
      },
      gramStainOptions: [
        { name: 'Gram positive' },
        { name: 'Gram negative' },
        { name: 'Acid fast' },
        { name: 'Endospore' },
        { name: 'Capsule' },
        { name: 'Flagella' },
        { name: 'Spirochete' }
      ],
      familyOptions: [
        { name: 'Ackermannviridae' },
        { name: 'Autographiviridae' },
        { name: 'Ampullaviridae' },
        { name: 'Bicaudaviridae' },
        { name: 'Clavaviridae' },
        { name: 'Corticoviridae' },
        { name: 'Cystoviridae' },
        { name: 'Drexlerviridae' },
        { name: 'Finnlakeviridae' },
        { name: 'Fuselloviridae' },
        { name: 'Globuloviridae' },
        { name: 'Guttaviridae' },
        { name: 'Herelliviridae' },
        { name: 'Inoviridae' },
        { name: 'Leviviridae' },
        { name: 'Lipothrixviridae' },
        { name: 'Microviridae' },
        { name: 'Myoviridae' },
        { name: 'Plasmaviridae' },
        { name: 'Pleolipoviridae' },
        { name: 'Podoviridae' },
        { name: 'Portogloboviridae' },
        { name: 'Rudiviridae' },
        { name: 'Siphoviridae' },
        { name: 'Sphaerolipoviridae' },
        { name: 'Spiraviridae' },
        { name: 'Tectiviridae' },
        { name: 'Tristromaviridae' },
        { name: 'Turriviridae' },
        { name: 'Unclassified' }
      ],
      selected: [],
      selectedPhage: null,
      // visual
      dialogDelete: false,
      isSelected: false,
      renderKey: 0
    }
  },
  components: {
    ManagePhredSubmissionEditor
  },
  methods: {
    wordToEnum,
    enumToWord,
    enumToCamel,
    getColor(value) {
      if (value === 'Gram positive') return 'purple--text'
      else if (value === 'Gram negative' || value === 'no') return 'red--text'
      else if (value === 'yes') return 'primary--text'
      else return 'gray'
    },
    deleteItem(item) {
      this.editedIndex = this.listPhages.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$apollo
        .mutate({
          mutation: deletePhredSubmission,
          variables: {
            input: {
              phage: this.editedItem.phage
            }
          }
        })
        .then((data) => {
          console.log(data) // DEBUG
          const notification = {
            type: 'success',
            message: 'Phage is successfully deleted'
          }
          this.$store.dispatch('addNotification', notification)
          this.listPhages.items.splice(this.editedIndex, 1)
          this.dialogDelete = false
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
          this.$gtag.event(`Delete`, {
            event_category: 'Phred: Submission',
            event_label: `A submitted phage entry is deleted`
          })
        })
        .catch((err) => {
          console.log(err) // DEBUG
          const notification = {
            type: 'error',
            message: 'Deletion is unsuccessful'
          }
          this.$store.dispatch('addNotification', notification)
        })
    },
    cancel() {
      // This is supposed to rerender the data-table --> Buggy
      this.renderKey += 1
      this.isSelected = false
      this.selected = []
      this.selectedPhage = null
    }
  },
  created() {
    this.gramStainOptions.forEach((item, i) => {
      this.gramStainOptions[i].value = this.wordToEnum(item.name)
    })
    this.familyOptions.forEach((item, i) => {
      this.familyOptions[i].value = this.wordToEnum(item.name)
    })
  },
  watch: {
    selected(newValue) {
      if (newValue.length === 1) {
        // Only single-select, so length should be 1 or 0
        this.selectedPhage = newValue[0]
        this.isSelected = true
      }
    }
  },
  apollo: {
    listPhages: {
      query: listPhages,
      variables: {
        limit: 1000
      },
      error(err) {
        console.log(err)
      }
    }
  }
}
</script>
