<template>
  <v-card class="mb-n2">
    <v-row class="mt-3">
      <v-card-title class="tour-phred-title ml-5">
        List of Users
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        @click="createUser"
        title="Create a user"
        class="mt-3 mr-6"
        color="primary"
        icon
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-col>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
        class="mt-n3 mb-2"
      ></v-text-field>
    </v-col>
    <v-data-table
      :headers="headers"
      :items="user_list"
      :search="search"
      :loading="user_list.length === 0 ? true : false"
      loading-text="Loading users... Please wait"
      v-model="selected"
      item-key="username"
      show-select
    ></v-data-table>
    <v-col>
      <v-autocomplete
        :items="user_list"
        item-text="email"
        label="Selected Users"
        outlined
        readonly
        clearable
        multiple
        chips
        class="mt-2"
        data-testid="users"
        v-model="selected"
        v-if="isSelected"
      ></v-autocomplete>
      <v-autocomplete
        :items="selected"
        item-text="email"
        label="Delete User Accounts"
        outlined
        clearable
        multiple
        deletable-chips
        chips
        class="mt-2"
        data-testid="users"
        v-model="selected_users_delete"
        v-if="isSelected"
      ></v-autocomplete>
      <v-autocomplete
        :items="selected"
        item-text="email"
        label="Reset User Passwords"
        outlined
        clearable
        multiple
        deletable-chips
        chips
        class="mt-2"
        data-testid="users"
        v-model="selected_users_reset"
        v-if="isSelected"
      ></v-autocomplete>
    </v-col>
    <v-card-title v-if="isSelected"> Save Changes </v-card-title>
    <v-card-text v-if="isSelected">
      Are you sure you want to do this? This action is irreversible. Please
      write <b>Save Changes</b> to enable deletion/password reset of the
      user(s).
    </v-card-text>
    <v-card-actions class="justify-center" v-if="isSelected">
      <v-form @submit.prevent="save" v-model="isFormValid">
        <v-row>
          <v-col>
            <v-text-field
              placeholder="Save Changes"
              :rules="[validateChanges]"
              solo
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-n5 mb-3">
          <v-btn type="submit" :disabled="!isFormValid" color="primary" large>
            Save
          </v-btn>
        </v-row>
      </v-form>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
  AdminSetUserPasswordCommand,
  AdminDeleteUserCommand,
  AdminListGroupsForUserCommand,
  AdminUserGlobalSignOutCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { validateChanges } from "@/utilities/validationRules";

const { SESClient, SendTemplatedEmailCommand } = require("@aws-sdk/client-ses");
const {
  SecretsManagerClient,
  GetRandomPasswordCommand,
} = require("@aws-sdk/client-secrets-manager");
const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const REGION = "us-west-2";

export default {
  data() {
    return {
      validateChanges,
      // List of user objects
      user_list: [],
      selected: [],
      // Dictionary used to associate an email with a username quickly
      email_map: {},
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: true,
          value: "username",
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Institution",
          align: "start",
          sortable: true,
          value: "institution",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Groups",
          align: "start",
          sortable: true,
          value: "groups",
        },
        {
          text: "Date Created",
          align: "start",
          sortable: true,
          value: "date_created",
        },
      ],
      isSelected: false,
      isFormValid: false,
      search: "",
      selected_users_delete: [],
      selected_users_reset: [],
    };
  },
  created() {
    this.get_users();
  },
  watch: {
    selected() {
      this.selected_users_delete = [];
      this.selected_users_reset = [];
      if (this.selected.length != 0) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    },
  },
  methods: {
    createUser() {
      this.$emit("switch-to-create-user");
    },
    get_users(client = null, paginationToken = undefined) {
      if (client === null) {
        // Register a new client
        let cognitoProviderName =
          "cognito-idp." +
          REGION +
          ".amazonaws.com/" +
          process.env.VUE_APP_COGNITO_POOL_ID;
        var client = new CognitoIdentityProviderClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId:
              process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
            logins: {
              [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
                .jwtToken,
            },
          }),
        });
      }

      // Get list of all users
      const input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        PaginationToken: paginationToken,
      };
      const command = new ListUsersCommand(input);
      client.send(command).then((data) => {
        for (let i = 0; i < data.Users.length; i++) {
          let single_user = {};
          console.log(data); // DELETE
          for (let j = 0; j < data.Users[i].Attributes.length; j++) {
            if (data.Users[i].Attributes[j].Name == "sub") {
              single_user.username = data.Users[i].Attributes[j].Value;
            }
            if (data.Users[i].Attributes[j].Name == "email") {
              single_user.email = data.Users[i].Attributes[j].Value;
            }
            if (data.Users[i].Attributes[j].Name == "name") {
              single_user.name = data.Users[i].Attributes[j].Value;
            }
            if (data.Users[i].Attributes[j].Name == "custom:organization") {
              single_user.institution = data.Users[i].Attributes[j].Value;
            }
          }
          single_user.date_created = data.Users[i].UserCreateDate;

          // Must get user groups and THEN push the user
          this.email_map[single_user.email] = single_user.username;
          this.get_user_groups(client, single_user);
        }

        // Paginate if necessary
        if (data.PaginationToken != undefined) {
          this.get_users(client, data.PaginationToken);
        }
      });
    },
    async save() {
      // Register a new client
      let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
      const client = new CognitoIdentityProviderClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
          }
        })
      });

      // Reset passwords
      for (let i = 0; i < this.selected_users_reset.length; i++) {
        // store email for each iteration to be used once we get a response from the aws calls
        const email = this.selected_users_reset[i];
        // get user name from first part of email
        const name = email.split("@")[0];
        // create a temporary password for the user
        const tempPassword = await this.generateRandomPassword();

        var input = {
          UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
          Username: this.email_map[this.selected_users_reset[i]],
          Password: tempPassword,
          Permanent: false,
        };
        var command = new AdminSetUserPasswordCommand(input);
        client
          .send(command)
          .then((data) => {
            console.log("Password reset successful: " + email);

            // log out the user from all sessions
            var input = {
              UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
              Username: this.email_map[email],
            };
            var command = new AdminUserGlobalSignOutCommand(input);
            client
              .send(command)
              .then((data) => {
                console.log("User has been logged out: " + email); // DEBUG
                console.log(data); // DEBUG
              })
              .catch((err) => {
                console.log("Failed to log out user: " + email); // DEBUG
                console.log(err); // DEBUG
              });

            // send an email to the user using Amazon SES to give them their temporary password
            const EmailClient = new SESClient({
              region: REGION,
              credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: REGION }),
                identityPoolId:
                  process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_SES,
                logins: {
                  [`${cognitoProviderName}`]: this.$store.state.user.session
                    .idToken.jwtToken,
                },
              }),
            });

            var input = {
              Source:
                "Bio-Conversion Databank Foundation <no-reply@bio-conversion.org>",
              Destination: {
                ToAddresses: [email],
              },
              Template: "AdminResetPassword",
              TemplateData:
                '{ "name": "' +
                name +
                '", "password": "' +
                tempPassword +
                '" }',
            };
            var command = new SendTemplatedEmailCommand(input);
            EmailClient.send(command)
              .then((data) => {
                console.log("Email sent: " + data);
              })
              .catch((err) => {
                console.error("Email failed to send: " + err); // DEBUG
                const notification = {
                  type: "error",
                  message: err.message,
                };
                this.$store.dispatch("addNotification", notification);
              });
          })
          .catch((err) => {
            console.error(err); // DEBUG
            const notification = {
              type: "error",
              message: err.message,
            };
            this.$store.dispatch("addNotification", notification);
          });
      }

      // Delete users
      for (let i = 0; i < this.selected_users_delete.length; i++) {
        var input = {
          UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
          Username: this.email_map[this.selected_users_delete[i]],
        };
        var command = new AdminDeleteUserCommand(input);
        client.send(command).catch((err) => {
          // console.error(err) // DEBUG
          const notification = {
            type: "error",
            message: err.message,
          };
          this.$store.dispatch("addNotification", notification);
        });
      }

      // Reset state
      this.selected = [];
      this.search = "";
      const notification = {
        type: "success",
        message: "Changes made successfully",
      };
      this.$store.dispatch("addNotification", notification);
    },
    get_user_groups(client, single_user, nextToken = undefined) {
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        Username: single_user.username,
        NextToken: nextToken,
      };
      var command = new AdminListGroupsForUserCommand(input);
      client
        .send(command)
        .then((data) => {
          single_user.groups = [];
          for (let i = 0; i < data.Groups.length; i++) {
            single_user.groups.push(data.Groups[i].GroupName);
          }
          this.user_list.push(single_user);

          // Paginate if necessary
          if (data.NextToken != undefined) {
            this.get_user_groups(client, single_user, data.NextToken);
          }
        })
        .catch((err) => {
          // console.error(err) // DEBUG
          const notification = {
            type: "error",
            message: err.message,
          };
          this.$store.dispatch("addNotification", notification);
        });
    },
    async generateRandomPassword() {
      // Register a new secrets manager client
      let cognitoProviderName =
        "cognito-idp." +
        REGION +
        ".amazonaws.com/" +
        process.env.VUE_APP_COGNITO_POOL_ID;
      const client = new SecretsManagerClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId:
            process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_SECRETS_MANAGER,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
              .jwtToken,
          },
        }),
      });

      // exclude the quotation characters so that the email template JSON is not misinterpreted later
      const input = {
        PasswordLength: 8,
        IncludeSpace: false,
        ExcludeCharacters: `"'`
      };

      const command = new GetRandomPasswordCommand(input);

      // wait for random password to generate before returning
      try {
        const data = await client.send(command);
        console.log("Random password generated: " + data.RandomPassword);

        return data.RandomPassword;
      } catch (err) {
        console.error(err);

        // if there is an unexpected error, then return a pre-determined "random" password (very unlikely to actually happen)
        return "xH9r&n@T";
      }
    },
  },
};
</script>

<style scoped>
.slide-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
</style>
