<template>
  <v-card class="mb-n2">
    <v-row class="mt-3">
      <v-col>
        <v-card-title class="tour-phred-title">
          Create A User
        </v-card-title>
        <v-form
          ref="createUserForm"
          v-model="isFormValid"
          @submit.prevent="onRegister"
        >
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  label="Email*"
                  v-model="email"
                  :rules="[validateEmail]"
                  outlined
                  clearable
                  full-width
                  height="20"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-autocomplete
                  :items="groups"
                  item-text="name"
                  label="Group"
                  outlined
                  clearable
                  chips
                  multiple
                  deletable-chips
                  height="20"
                  data-testid="group"
                  v-model="selected_groups"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-row justify="center">
            <v-btn
              color="primary"
              type="submit"
              large
              elevation="6"
              data-testid="register"
              :disabled="!isFormValid"
              class="mb-4"
            >
              Submit
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import UserPool from "@/plugins/UserPool";
import { validateEmail } from "@/utilities/validationRules";
import {
  CognitoIdentityProviderClient,
  ListGroupsCommand,
  AdminAddUserToGroupCommand,
  AdminCreateUserCommand,
  AdminUpdateUserAttributesCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

const {
  fromCognitoIdentityPool,
} = require("@aws-sdk/credential-provider-cognito-identity");
const { CognitoIdentityClient } = require("@aws-sdk/client-cognito-identity");
const REGION = "us-west-2";

export default {
  data() {
    return {
      // Initialize imported function
      validateEmail,
      // Registration data
      email: "",
      selected_groups: [],
      isFormValid: false,
      // Group info
      groups: [],
    };
  },
  created() {
    this.get_groups();
  },
  methods: {
    onRegister() {
      let cognitoProviderName =
        "cognito-idp." +
        REGION +
        ".amazonaws.com/" +
        process.env.VUE_APP_COGNITO_POOL_ID;
      const client = new CognitoIdentityProviderClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId:
            process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
              .jwtToken,
          },
        }),
      });

      const input = {
        Username: this.email,
        UserPoolId: UserPool.getUserPoolId(),
        DesiredDeliveryMediums: ["EMAIL"],
      };

      const command = new AdminCreateUserCommand(input);

      client
        .send(command)
        .then((data) => {
          // console.log(data); // DEBUG

          // Add user to specified groups
          this.add_to_groups(data.User.Username);

          // verify user email
          this.verifyEmail(data.User.Username);

          // find user email attribute from list of attributes
          const emailAttribute = data.User.Attributes.find((attribute) => {
            return attribute.Name == "email"
          })

          // show a notification with the email of the created user
          const notification = {
            type: "success",
            message: "Created user, " + emailAttribute.Value,
          };
          this.$store.dispatch("addNotification", notification);

          // clear form once we get a response
          this.$refs.createUserForm.reset();
        })
        .catch((err) => {
          // console.log(err); // DEBUG

          const notification = {
            type: "error",
            message: err.message,
          };
          this.$store.dispatch("addNotification", notification);

          // clear form once we get a response
          this.$refs.createUserForm.reset();
        });
    },
    get_groups(client = null, nextToken = undefined) {
      if (client === null) {
        // Register a new client
        let cognitoProviderName =
          "cognito-idp." +
          REGION +
          ".amazonaws.com/" +
          process.env.VUE_APP_COGNITO_POOL_ID;
        var client = new CognitoIdentityProviderClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId:
              process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_USERSTATS,
            logins: {
              [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
                .jwtToken,
            },
          }),
        });
      }

      // Get list of groups
      const input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
      };
      const command = new ListGroupsCommand(input);
      client.send(command).then((data) => {
        for (let i = 0; i < data.Groups.length; i++) {
          this.groups.push(data.Groups[i].GroupName);
        }
        if (data.NextToken != undefined) {
          this.get_groups(client, data.NextToken);
        }
      });
    },
    add_to_groups(username) {
      // Register a new client
      let cognitoProviderName =
        "cognito-idp." +
        REGION +
        ".amazonaws.com/" +
        process.env.VUE_APP_COGNITO_POOL_ID;
      const client = new CognitoIdentityProviderClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId:
            process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
              .jwtToken,
          },
        }),
      });

      for (let i = 0; i < this.selected_groups.length; i++) {
        // Get list of groups
        const input = {
          UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
          GroupName: this.selected_groups[i],
          Username: username,
        };
        const command = new AdminAddUserToGroupCommand(input);
        client
          .send(command)
          .then((data) => {
            console.log("Added to group: " + data); // DEBUG
          })
          .catch((err) => {
            console.log(err); // DEBUG
          });
      }
    },
    verifyEmail(username) {
      // Register a new client
      let cognitoProviderName =
        "cognito-idp." +
        REGION +
        ".amazonaws.com/" +
        process.env.VUE_APP_COGNITO_POOL_ID;
      const client = new CognitoIdentityProviderClient({
        region: REGION,
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: REGION }),
          identityPoolId:
            process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
          logins: {
            [`${cognitoProviderName}`]: this.$store.state.user.session.idToken
              .jwtToken,
          },
        }),
      });

      // set email_verified to true
      let attributeEmailVerified = new CognitoUserAttribute({
        Name: "email_verified",
        Value: "true",
      });

      const input = {
        Username: username,
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        UserAttributes: [attributeEmailVerified],
      };

      const command = new AdminUpdateUserAttributesCommand(input);

      client
        .send(command)
        .then((data) => {
          console.log("User email verified: " + data); // DEBUG
        })
        .catch((err) => {
          console.log(err); // DEBUG
        });
    },
  },
};
</script>

<style scoped>
.slide-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
</style>
