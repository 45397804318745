<template>
  <v-card class="mb-n2 mt-10">
    <v-row class="mt-3">
      <v-card-title class="tour-phred-title ml-5">
        List of Groups
      </v-card-title>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="group_list"
      :search="search"
      :loading="group_list.length === 0 ? true : false"
      loading-text="Loading groups... Please wait"
      item-key="name"
    ></v-data-table>
  </v-card>
</template>

<script>
import {
  CognitoIdentityProviderClient,
  ListGroupsCommand,
  ListUsersInGroupCommand
} from "@aws-sdk/client-cognito-identity-provider";

const { fromCognitoIdentityPool } = require('@aws-sdk/credential-provider-cognito-identity')
const { CognitoIdentityClient } = require('@aws-sdk/client-cognito-identity')
const REGION = "us-west-2";

export default {
  data() {
    return {
      // Array of group objects
      group_list: [],
      headers: [
        {
          text: 'Group',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Precedence',
          align: 'start',
          sortable: true,
          value: 'precedence'
        },
        {
          text: 'Number of Users',
          align: 'start',
          sortable: true,
          value: 'num_users'
        },
        {
          text: 'Users',
          align: 'start',
          sortable: true,
          value: 'users'
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: true,
          value: 'date_created'
        }
      ]
    }
  },
  created() {
    this.get_groups()
  },
  props: [
    'search'
  ],
  methods: {
    get_groups(client=null, nextToken=undefined) {
      if (client === null) {
        // Register a new client
        let cognitoProviderName = "cognito-idp." + REGION + ".amazonaws.com/" + process.env.VUE_APP_COGNITO_POOL_ID
        var client = new CognitoIdentityProviderClient({
          region: REGION,
          credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: REGION }),
            identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID_MANAGEUSERSANDGROUPS,
            logins: {
              [`${cognitoProviderName}`]: this.$store.state.user.session.idToken.jwtToken
            }
          })
        });
      }
      
      // Get list of groups
      var input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        NextToken: nextToken
      }
      var command = new ListGroupsCommand(input);
      client.send(command).then(data => {
        for (let i = 0; i < data.Groups.length; i++) {
          let single_group = {}
          single_group.name = data.Groups[i].GroupName
          single_group.precedence = data.Groups[i].Precedence
          single_group.date_created = data.Groups[i].CreationDate
          this.get_users(client, single_group)
        }

        // Paginate if necessary
        if (data.NextToken != undefined) {
          this.get_groups(client, data.NextToken)
        }
      })
    },
    get_users(client, single_group, nextToken=undefined) {
      // Get list of users in the group
      const input = {
        UserPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
        GroupName: single_group.name,
        NextToken: nextToken
      }
      const command = new ListUsersInGroupCommand(input);
      client.send(command).then(data => {
        let users = [];
        for (let i = 0; i < data.Users.length; i++) {
          for (let j = 0; j < data.Users[i].Attributes.length; j++) {
            if (data.Users[i].Attributes[j].Name == "email") {
              users.push(data.Users[i].Attributes[j].Value)
              break;
            }
          }
        }

        // Paginate if necessary
        if (data.NextToken != undefined) {
          this.get_included_users(client, data.NextToken)
        } else {
          single_group.users = users
          single_group.num_users = users.length
          this.group_list.push(single_group)
        }
      })
    }
  }
}
</script>

<style scoped>
.slide-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
</style>
